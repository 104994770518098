import React, { useState, useEffect, useRef } from "react";
import "./index.css";

const WhitePaper = () => {
  const [activeSection, setActiveSection] = useState("abstract");
  const sections = [
    { id: "abstract", title: "Abstract" },
    { id: "introduction", title: "Introduction" },
    { id: "dhive-l1", title: "Dhive L1 Blockchain" },
    { id: "loyalty-programs", title: "Tokenized Loyalty Programs" },
    { id: "rnft-marketplace", title: "rNFT Marketplace" },
    { id: "tokenomics", title: "Tokenomics" },
    { id: "amm", title: "Automated Market Maker" },
    { id: "bridge", title: "Cross-Chain Bridge" },
    { id: "roadmap", title: "Roadmap" },
    { id: "conclusion", title: "Conclusion" },
  ];

  const sectionRefs = useRef({});

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.6, rootMargin: "0px 0px -40% 0px" }
    );

    sections.forEach(({ id }) => {
      if (sectionRefs.current[id]) {
        observer.observe(sectionRefs.current[id]);
      }
    });

    return () => observer.disconnect();
  }, []);

  const scrollToSection = (id) => {
    sectionRefs.current[id]?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div className="white-paper-container">
      {/* Sticky Sidebar */}
      <nav className="sidebar">
        <div className="sidebar-header">
          <h2>Dhive Protocol</h2>
          <p>Tokenize your real-world business</p>
        </div>
        <ul>
          {sections.map(({ id, title }) => (
            <li
              key={id}
              className={activeSection === id ? "active" : ""}
              onClick={() => scrollToSection(id)}
            >
              {title}
            </li>
          ))}
        </ul>
      </nav>

      {/* Main Content */}
      <main className="main-content">
        {/* Header */}
        <header className="document-header">
          <h1>Dhive Protocol</h1>
          <h2>Tokenize your real-world business effortlessly in seconds</h2>
          <p className="document-date">January 28, 2025</p>
        </header>

        {/* Abstract Section */}
        <section
          id="abstract"
          ref={(el) => (sectionRefs.current["abstract"] = el)}
          className="content-section"
        >
          <h2>Abstract</h2>
          <p>
            The Dhive Protocol is set to revolutionize the loyalty rewards and
            online review industries by introducing greater transparency,
            security, and efficiency. In the realm of loyalty programs, Dhive
            enables seamless interoperability across brands, mitigates fraud
            through immutable blockchain records, and facilitates instant reward
            redemption via smart contracts.
          </p>
          <p>
            Additionally, tokenization enhances user engagement by transforming
            rewards into tradeable digital assets with tangible value. Within
            the online review ecosystem, Dhive ensures authenticity by
            restricting reviews to verified buyers, preventing manipulation, and
            decentralizing reputation management.
          </p>
          <p>
            By leveraging blockchain technology, the Dhive Protocol establishes
            a trust-driven and decentralized framework for loyalty and
            reputation systems.
          </p>
        </section>

        {/* Introduction Section */}
        <section
          id="introduction"
          ref={(el) => (sectionRefs.current["introduction"] = el)}
          className="content-section"
        >
          <h2>Introduction</h2>
          <p>
            Dhive is a next-generation protocol designed to streamline the
            tokenization of business inventories and loyalty rewards on the
            Dhive L1 blockchain. Officially launched on January 28, 2025, Dhive
            empowers businesses with an intuitive and cost-effective solution to
            create, manage, and distribute digital assets such as loyalty tokens
            and rNFTs (Redeemable NFTs).
          </p>

          <h3>Key Benefits of the Dhive Protocol</h3>
          <ol>
            <li>
              <strong>Simplifying Blockchain-Based Inventory Management</strong>
              <p>
                Traditional blockchain inventory solutions are complex and
                difficult to integrate into consumer-facing applications. Dhive
                provides a seamless, plug-and-play experience—similar to
                Shopify—allowing brick-and-mortar businesses to quickly deploy
                blockchain-powered inventory management with minimal technical
                expertise.
              </p>
            </li>
            <li>
              <strong>
                Enhancing Loyalty Programs with Blockchain Technology
              </strong>
              <p>
                Dhive revolutionizes loyalty programs by leveraging blockchain's
                transparency, security, and interoperability. It eliminates
                intermediaries, reduces costs, enables real-time and
                cross-border reward redemption, and supports gamified
                incentives.
              </p>
            </li>
            <li>
              <strong>Monetizing Tokenized Loyalty Programs</strong>
              <p>
                With Dhive, businesses can transform their loyalty programs into
                revenue-generating assets. By leveraging decentralized
                engagement models, companies can boost customer retention while
                unlocking new monetization opportunities—ensuring long-term
                growth and sustainability.
              </p>
            </li>
          </ol>
        </section>

        {/* Dhive L1 Blockchain Section */}
        <section
          id="dhive-l1"
          ref={(el) => (sectionRefs.current["dhive-l1"] = el)}
          className="content-section"
        >
          <h2>Dhive L1: A Scalable, EVM-Compatible Blockchain</h2>
          <p>
            Dhive is a high-performance, Proof-of-Stake (PoS) Layer 1 blockchain
            engineered for full Ethereum Virtual Machine (EVM) compatibility and
            cross-chain interoperability. Built using the Cosmos SDK, Dhive
            operates atop the CometBFT (a fork of Tendermint Core) consensus
            engine, delivering rapid finality, high transaction throughput, and
            a low-latency block time of approximately 2 seconds.
          </p>

          <h3>Core Architectural Features</h3>
          <ul>
            <li>
              <strong>Cosmos SDK Integration</strong> – Modular framework
              enabling blockchain composability and extensibility.
            </li>
            <li>
              <strong>CometBFT Application Blockchain Interface (ABCI)</strong>{" "}
              – Decouples consensus from application logic.
            </li>
            <li>
              <strong>Geth Library Utilization</strong> – Enhances code
              maintainability and promotes seamless adoption of Ethereum's
              existing tooling.
            </li>
            <li>
              <strong>Web3 JSON-RPC Layer</strong> – Provides full
              interoperability with Ethereum-native wallets and developer tools.
            </li>
          </ul>
        </section>

        {/* Tokenized Loyalty Programs Section */}
        <section
          id="loyalty-programs"
          ref={(el) => (sectionRefs.current["loyalty-programs"] = el)}
          className="content-section"
        >
          <h2>Tokenization of Loyalty Programs on Dhive</h2>
          <p>
            Dhive's tokenized loyalty programs have the potential to redefine
            customer engagement, revolutionize brand-consumer relationships, and
            transform the global rewards economy.
          </p>

          <div className="feature-box">
            <h4>1. Seamless Interoperability Across Industries and Borders</h4>
            <p>
              Traditional loyalty programs are often siloed, restricting
              customers to redeem points within a single brand or network.
            </p>
            <p className="example">
              ✅ Example: A customer earning airline loyalty tokens could
              seamlessly redeem them for hotel stays, retail purchases, or
              digital assets.
            </p>
          </div>

          <div className="feature-box">
            <h4>2. True Ownership and Liquidity of Loyalty Assets</h4>
            <p>
              Tokenizing loyalty programs on Dhive protocol enables the
              conversion of rewards into digital assets that users can trade,
              sell, or transfer via the Dhive decentralized exchange (DEX).
            </p>
          </div>

          <div className="feature-box">
            <h4>3. Enhanced Security, Transparency, and Fraud Prevention</h4>
            <p>
              Dhive's immutable blockchain ledger ensures that all transactions
              are secure, transparent, and verifiable, significantly mitigating
              fraud risks.
            </p>
          </div>

          <div className="feature-box">
            <h4>4. Unlocking New Monetization Models for Businesses</h4>
            <p>
              Tokenized loyalty programs introduce new revenue opportunities for
              businesses by creating secondary markets where users can buy,
              sell, or stake their loyalty tokens.
            </p>
          </div>
        </section>

        {/* rNFT Marketplace Section */}
        <section
          id="rnft-marketplace"
          ref={(el) => (sectionRefs.current["rnft-marketplace"] = el)}
          className="content-section"
        >
          <h2>Dhive rNFT Marketplace</h2>
          <p>
            Dhive is a cutting-edge rNFT (redeemable Non-Fungible Token)
            marketplace designed to empower brands in seamlessly tokenizing
            their products and services through advanced Web3 technology.
          </p>

          <h3>Why Choose Dhive?</h3>
          <ul className="checklist">
            <li>No-Code Blockchain Deployment</li>
            <li>rNFT-Based Selling Model</li>
            <li>Secure & Transparent Transactions</li>
            <li>Verified On-Chain Reviews</li>
            <li>Free Blockchain Inventory Management</li>
          </ul>

          <h3>What Are Redeemable NFTs?</h3>
          <p>
            Redeemable NFTs are a unique category of non-fungible tokens (NFTs)
            that grant holders the ability to exchange their digital asset for a
            corresponding physical product.
          </p>

          <div className="process-flow">
            <div className="process-step">
              <h4>Minting</h4>
              <p>Created and linked to a specific physical asset</p>
            </div>
            <div className="process-step">
              <h4>Ownership</h4>
              <p>Bought, sold, or traded on marketplaces</p>
            </div>
            <div className="process-step">
              <h4>Redemption</h4>
              <p>Claim physical item with various outcomes</p>
            </div>
          </div>
        </section>

        {/* Tokenomics Section */}
        <section
          id="tokenomics"
          ref={(el) => (sectionRefs.current["tokenomics"] = el)}
          className="content-section"
        >
          <h2>Dhive Protocol Tokenomics</h2>
          <p>
            The Dhive Protocol's tokenomics framework is designed to ensure
            economic sustainability, decentralized governance, and network
            security through a dual-token model.
          </p>

          <div className="tokenomics-grid">
            <div className="token-card">
              <h3>DFuel (DFUEL)</h3>
              <p>
                <strong>Max Supply:</strong> 15 million
              </p>
              <p>
                <strong>Purpose:</strong> Utility token for transactions and
                fees
              </p>
              <ul>
                <li>Transaction fee coverage</li>
                <li>Business creation fees</li>
                <li>Deflationary burn mechanism</li>
              </ul>
            </div>

            <div className="token-card">
              <h3>Dhive (DHIVE)</h3>
              <p>
                <strong>Max Supply:</strong> 10 billion
              </p>
              <p>
                <strong>Purpose:</strong> Governance and staking
              </p>
              <ul>
                <li>Validator node operation</li>
                <li>Protocol governance</li>
                <li>Staking rewards</li>
              </ul>
            </div>
          </div>

          <h3>Economic Sustainability Mechanisms</h3>
          <div className="mechanisms">
            <div>
              <h4>Transaction Fees</h4>
              <p>50% burned, 50% to validators</p>
            </div>
            <div>
              <h4>Trading Fees</h4>
              <p>0.3% fee with buyback and burn</p>
            </div>
            <div>
              <h4>Cashback</h4>
              <p>50% buyback, 50% loyalty tokens</p>
            </div>
          </div>
        </section>

        {/* AMM Section */}
        <section
          id="amm"
          ref={(el) => (sectionRefs.current["amm"] = el)}
          className="content-section"
        >
          <h2>Dhive Automated Market Maker</h2>
          <p>
            Dhive is a next-generation Automated Market Maker (AMM), with its
            Decentralized Exchange (DEX) serving as the foundation of its
            ecosystem.
          </p>

          <h3>Key Features</h3>
          <div className="feature-columns">
            <div>
              <h4>Swapping & Trading</h4>
              <p>Trustless trading directly through personal wallets</p>
            </div>
            <div>
              <h4>Liquidity Pools</h4>
              <p>Incentivized liquidity provision with LP tokens</p>
            </div>
            <div>
              <h4>Fee Structure</h4>
              <p>0.3% trading fee with optimized distribution</p>
            </div>
          </div>
        </section>

        {/* Bridge Section */}
        <section
          id="bridge"
          ref={(el) => (sectionRefs.current["bridge"] = el)}
          className="content-section"
        >
          <h2>Dhive Bridge: Seamless Cross-Chain Asset Transfers</h2>
          <p>
            Dhive Bridge is the official interoperability gateway for the Dhive
            network, designed for secure and efficient cross-chain transfers.
          </p>

          <h3>Key Features</h3>
          <ul className="feature-list">
            <li>
              Secure Cross-Chain Transfers with cryptographic verification
            </li>
            <li>Low-Cost & High-Speed Transactions</li>
            <li>Decentralized & Trust-Minimized architecture</li>
            <li>Interoperability across major blockchain networks</li>
          </ul>

          <div className="cta-box">
            <p>Bridge your assets today:</p>
            <a
              href="https://dhive.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://dhive.org
            </a>
          </div>
        </section>

        {/* Roadmap Section */}
        <section
          id="roadmap"
          ref={(el) => (sectionRefs.current["roadmap"] = el)}
          className="content-section"
        >
          <h2>Dhive Project Roadmap: 2025 Strategic Plan</h2>

          <div className="roadmap-container">
            <div className="roadmap-phase">
              <h3>Q1 2025: Foundation</h3>
              <ul>
                <li>Onboard 50 validators</li>
                <li>Launch Dhive L1 blockchain</li>
                <li>Integrate 20 businesses</li>
              </ul>
            </div>

            <div className="roadmap-phase">
              <h3>Q2 2025: Growth</h3>
              <ul>
                <li>100,000 active users</li>
                <li>1,000 business integrations</li>
                <li>Exchange listings</li>
              </ul>
            </div>

            <div className="roadmap-phase">
              <h3>Q3 2025: Expansion</h3>
              <ul>
                <li>400,000 active users</li>
                <li>$200M daily volume</li>
                <li>5,000 businesses</li>
              </ul>
            </div>

            <div className="roadmap-phase">
              <h3>Q4 2025: Leadership</h3>
              <ul>
                <li>1,000,000 users</li>
                <li>$1B daily volume</li>
                <li>15,000 businesses</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Conclusion Section */}
        <section
          id="conclusion"
          ref={(el) => (sectionRefs.current["conclusion"] = el)}
          className="content-section"
        >
          <h2>Conclusion</h2>
          <p>
            Dhive is a cutting-edge blockchain-based solution designed to
            transform the loyalty rewards industry by addressing its fundamental
            challenges—illiquidity, limited accessibility, and inefficiencies in
            traditional loyalty programs.
          </p>

          <div className="summary-box">
            <h3>Key Transformations</h3>
            <ul>
              <li>Creates liquid marketplace for loyalty rewards</li>
              <li>Enables global interoperability of reward points</li>
              <li>Reduces operational costs through smart contracts</li>
              <li>Provides new revenue streams for businesses</li>
            </ul>
          </div>

          <p className="closing-statement">
            By bridging the gap between traditional loyalty programs and
            blockchain technology, Dhive is set to redefine the industry, making
            loyalty rewards more valuable, accessible, and globally
            transferable.
          </p>
        </section>
      </main>
    </div>
  );
};

export default WhitePaper;
