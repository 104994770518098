import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import ERC20_ABI from "../../../Exchange/ExchangePages/ExchangeTrade/Liquidity/erc20Abi.json";

const DEFAULT_NETWORK = {
  id: "0",
  network: "EGOCHAIN NETWORK",
  symbol: "USDC",
  contractAddress: "0xF5121E85b239F650063637B8Df45DcEd2c88976c",
  rpc: "https://mainnet.egochain.org",
  baseToken: "EGAX",
  createdAt: "2025-01-27 16:59:47",
  updatedAt: "2025-01-27 16:59:47",
  img: "/img/egax_logo.png",
  webRPC: "wss://www.blockhead-rpc.dhive.org",
};

const initialState = {
  loading: true,
  error: "",
  isSuccess: false,
  balanceLoading: true,
  balanceError: null,
  balanceChange: null,
  providers: [],
  activeProvider: DEFAULT_NETWORK,

  tokensByNetwork: {},
  nativeTokensByNetwork: {},
  balanceState: {
    byNetwork: {},
    listeners: {},
    lastFetched: null,
  },
  websocketProviders: {},
};

// Async Thunks

const calculateTotalTokenValue = (
  tokens,
  tokensByNetwork,
  nativeTokensByNetwork,
  networkName
) => {
  // Add defensive checks
  if (!tokens || !tokensByNetwork || !nativeTokensByNetwork || !networkName) {
    return "0";
  }

  let totalValue = ethers.BigNumber.from(0);

  // Combine all tokens (both native and ERC20) for the network
  const allNetworkTokens = [
    ...(nativeTokensByNetwork[networkName] || []),
    ...(tokensByNetwork[networkName] || []),
  ];

  // Calculate value for each token with balance
  for (const tokenAddress in tokens) {
    if (tokens[tokenAddress].hasValue) {
      try {
        // Find token data (check both native and ERC20 tokens)
        const tokenData = allNetworkTokens.find(
          (token) =>
            (token.tokenType === "native" && tokenAddress === "native") ||
            token.tokenB?.toLowerCase() === tokenAddress.toLowerCase()
        );

        if (!tokenData?.currentPrice) continue;

        const decimals = tokenData.decimals || 18;
        const price = tokenData.currentPrice || "0";

        // Convert balance and price to BigNumbers
        const balanceBN = ethers.utils.parseUnits(
          tokens[tokenAddress].balance,
          decimals
        );
        const priceBN = ethers.utils.parseUnits(price, 18);

        // Calculate value: (balance * price) / (10^decimals)
        const tokenValue = balanceBN
          .mul(priceBN)
          .div(ethers.BigNumber.from(10).pow(decimals));

        totalValue = totalValue.add(tokenValue);
      } catch (error) {
        console.error(`Error calculating value for ${tokenAddress}:`, error);
      }
    }
  }

  return ethers.utils.formatUnits(totalValue, 18);
};

export const initializeBalanceTracking = createAsyncThunk(
  "assets/initializeBalanceTracking",
  async ({ networkName, walletAddress }, { dispatch, getState }) => {
    const state = getState().assets;

    // Check if webRPC exists for this network
    const providerInfo = state.providers.find((p) => p.network === networkName);
    if (!providerInfo?.webRPC) {
      console.warn(`WebSocket RPC not configured for network: ${networkName}`);
      return;
    }

    if (!state.balanceState.byNetwork[networkName]) {
      dispatch(initNetworkBalances({ networkName }));
    }

    // Setup WebSocket listener if not already exists
    if (!state.websocketProviders[networkName]) {
      const wsProvider = new ethers.providers.WebSocketProvider(
        providerInfo.webRPC
      );
      dispatch(addWebSocketProvider({ networkName, provider: wsProvider }));

      // Listen for ERC20 transfers to this address
      const transferFilter = {
        topics: [
          ethers.utils.id("Transfer(address,address,uint256)"),
          null,
          ethers.utils.hexZeroPad(walletAddress, 32),
        ],
      };

      wsProvider.on(transferFilter, (log) => {
        const tokenAddress = log.address.toLowerCase();
        dispatch(
          fetchTokenBalance({
            networkName,
            walletAddress,
            tokenAddress,
            isNative: false,
          })
        );
      });
    }

    // Initial fetch of all balances
    dispatch(fetchAllBalances({ networkName, walletAddress }));
  }
);

export const fetchAllBalances = createAsyncThunk(
  "assets/fetchAllBalances",
  async ({ networkName, walletAddress }, { dispatch, getState }) => {
    const state = getState().assets;

    // Check if webRPC exists for this network
    const providerInfo = state.providers.find((p) => p.network === networkName);
    if (!providerInfo?.webRPC) {
      console.warn(`WebSocket RPC not configured for network: ${networkName}`);
      return { networkName, skipped: true };
    }

    const tokens = [
      ...(state.tokensByNetwork[networkName] || []),
      ...(state.nativeTokensByNetwork[networkName] || []),
    ];

    await Promise.all(
      tokens.map((token) => {
        const address = token.tokenType === "native" ? "native" : token.tokenB;
        return dispatch(
          fetchTokenBalance({
            networkName,
            walletAddress,
            tokenAddress: address,
            isNative: token.tokenType === "native",
            tokenData: token,
          })
        );
      })
    );

    return { networkName };
  }
);

export const fetchTokenBalance = createAsyncThunk(
  "assets/fetchTokenBalance",
  async (
    {
      networkName,
      walletAddress,
      tokenAddress,
      isNative,
      tokenData,
      silentUpdate = false,
    },
    { dispatch, getState }
  ) => {
    const state = getState().assets;

    // Check if webRPC exists for this network
    const providerInfo = state.providers.find((p) => p.network === networkName);
    if (!providerInfo?.webRPC) {
      console.warn(`WebSocket RPC not configured for network: ${networkName}`);
      return;
    }

    const provider = new ethers.providers.JsonRpcProvider(
      state.activeProvider.rpc
    );

    if (!silentUpdate) {
      dispatch(setBalanceLoading({ networkName, tokenAddress }));
    }

    try {
      let balance;
      if (isNative) {
        balance = await provider.getBalance(walletAddress);
        balance = ethers.utils.formatEther(balance);
        silentUpdate
          ? dispatch(silentUpdateNativeBalance({ networkName, balance }))
          : dispatch(updateNativeBalance({ networkName, balance }));
      } else {
        const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
        balance = await contract.balanceOf(walletAddress);
        const decimals = tokenData?.decimals || 18;
        balance = ethers.utils.formatUnits(balance, decimals);
        silentUpdate
          ? dispatch(
              silentUpdateTokenBalance({ networkName, tokenAddress, balance })
            )
          : dispatch(
              updateTokenBalance({ networkName, tokenAddress, balance })
            );
      }

      return { networkName, tokenAddress, balance };
    } catch (error) {
      if (!silentUpdate) {
        dispatch(
          setBalanceError({ networkName, tokenAddress, error: error.message })
        );
      }
      throw error;
    }
  }
);

// New thunk for trade-triggered updates
export const updateBalancesAfterTrade = createAsyncThunk(
  "assets/updateBalancesAfterTrade",
  async ({ networkName, walletAddress, tokenAddresses }, { dispatch }) => {
    // Always update native balance after a trade (for gas fees)
    await dispatch(
      fetchTokenBalance({
        networkName,
        walletAddress,
        tokenAddress: "native",
        isNative: true,
        silentUpdate: true,
      })
    );

    // Update specified token balances
    if (tokenAddresses && tokenAddresses.length > 0) {
      await Promise.all(
        tokenAddresses.map((tokenAddress) =>
          dispatch(
            fetchTokenBalance({
              networkName,
              walletAddress,
              tokenAddress,
              isNative: false,
              silentUpdate: true,
            })
          )
        )
      );
    }

    // Optionally: trigger a full balance refresh
    // dispatch(fetchAllBalances({ networkName, walletAddress }));
  }
);

const assetSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setAssetsPending: (state) => {
      state.loading = true;
      state.isSuccess = false;
      state.error = null;
    },
    setAssetsFailure: (state, action) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
    setAssets: (state, action) => {
      const tokens = action.payload;
      state.tokensByNetwork = {};
      state.nativeTokensByNetwork = {};

      tokens.forEach((token) => {
        try {
          if (token?.chains != null || token?.chains != undefined) {
            const chains = JSON.parse(token?.chains);
            chains.forEach((chain) => {
              const network = chain.network;
              if (!state.tokensByNetwork[network]) {
                state.tokensByNetwork[network] = [];
              }
              if (!state.nativeTokensByNetwork[network]) {
                state.nativeTokensByNetwork[network] = [];
              }

              if (token?.tokenType == "native") {
                state.nativeTokensByNetwork[network].push(token);
              } else {
                state.tokensByNetwork[network].push({
                  ...token,
                });
              }
            });
          }
        } catch (error) {
          console.error(
            "Error parsing chains for token:",
            token?.tokenType,
            error
          );
        }
      });

      state.loading = false;
    },
    addNewAsset: (state, action) => {
      // state.Assets.push(action.payload);
    },
    triggerBalanceChange: (state) => {
      state.balanceChange = !state.balanceChange;
    },
    setProviders: (state, action) => {
      state.providers = {};
      //default to null
      const defaultNetwork = {
        id: "0",
        network: "EGOCHAIN NETWORK",
        symbol: "USDC",
        contractAddress: "0xF5121E85b239F650063637B8Df45DcEd2c88976c",
        rpc: "https://mainnet.egochain.org",
        baseToken: "EGAX",
        createdAt: "2025-01-27 16:59:47",
        updatedAt: "2025-01-27 16:59:47",
        img: "https://res.cloudinary.com/itechsuite/image/upload/c_thumb,w_200,g_face/v1738169483/a5fckqt8vkjjdfnb737.png",
        webRPC: "wss://www.blockhead-rpc.dhive.org",
      };

      const updatedProviders = action.payload.map((provider) => {
        return {
          ...provider,
          webRPC: provider.rpc,
        };
      });

      state.providers = [defaultNetwork, ...updatedProviders];

      // Dispatch to setActiveProvider with the first provider's network
      if (state.providers.length > 0) {
        // In Redux Toolkit, you can directly mutate the state
        state.activeProvider = state.providers[0];

        // Alternatively, if you need to call the other reducer:
        // This assumes you're using Redux Toolkit's createSlice
        // and the reducers are in the same slice
        // return setActiveProvider(state, { payload: state.providers[0].network });
      }
    },
    setActiveProvider: (state, action) => {
      const _new = state.providers.find((dd) => dd.network === action.payload);
      if (_new) {
        state.activeProvider = _new;
      }
    },
    initNetworkBalances: (state, action) => {
      const { networkName } = action.payload;
      if (!state.balanceState.byNetwork[networkName]) {
        state.balanceState.byNetwork[networkName] = {
          native: { balance: "0", lastUpdated: null, status: "idle" },
          tokens: {},
          totalTokenValue: "0", // Add this line
        };
      }
      if (!state.balanceState.listeners[networkName]) {
        state.balanceState.listeners[networkName] = {
          native: false,
          tokens: [],
        };
      }
    },
    updateTokenBalance: (state, action) => {
      const { networkName, tokenAddress, balance } = action.payload;
      const tokenKey = tokenAddress.toLowerCase();

      if (!state.balanceState.byNetwork[networkName]) {
        state.balanceState.byNetwork[networkName] = { tokens: {} };
      }

      state.balanceState.byNetwork[networkName].tokens[tokenKey] = {
        balance,
        lastUpdated: Date.now(),
        status: "succeeded",
        hasValue: true,
      };

      // Recalculate total including price
      state.balanceState.byNetwork[networkName].totalTokenValue =
        calculateTotalTokenValue(
          state.balanceState.byNetwork[networkName].tokens,
          state.tokensByNetwork,
          state.nativeTokensByNetwork,
          networkName
        );
    },

    updateNativeBalance: (state, action) => {
      const { networkName, balance } = action.payload;

      if (!state.balanceState.byNetwork[networkName]) {
        state.balanceState.byNetwork[networkName] = {
          native: { balance: "0" },
          tokens: {},
          totalTokenValue: "0",
        };
      }

      state.balanceState.byNetwork[networkName].native = {
        balance,
        lastUpdated: Date.now(),
        status: "succeeded",
        hasValue: true,
      };

      // Recalculate total including native balance value
      const nativeToken = state.nativeTokensByNetwork[networkName]?.find(
        (t) => t.tokenType === "native"
      );
      if (nativeToken?.currentPrice) {
        const nativeValue = ethers.utils
          .parseEther(balance || "0")
          .mul(ethers.utils.parseEther(nativeToken.currentPrice));

        const tokensValue = ethers.utils.parseEther(
          calculateTotalTokenValue(
            state.balanceState.byNetwork[networkName].tokens,
            state.tokensByNetwork,
            state.nativeTokensByNetwork,
            networkName
          ) || "0"
        );

        state.balanceState.byNetwork[networkName].totalTokenValue =
          ethers.utils.formatEther(nativeValue.add(tokensValue));
      }
    },
    silentUpdateTokenBalance: (state, action) => {
      const { networkName, tokenAddress, balance } = action.payload;
      const tokenKey = tokenAddress.toLowerCase();

      if (state.balanceState.byNetwork[networkName]?.tokens[tokenKey]) {
        state.balanceState.byNetwork[networkName].tokens[tokenKey].balance =
          balance;
        state.balanceState.byNetwork[networkName].tokens[tokenKey].lastUpdated =
          Date.now();

        // Recalculate total - pass all required parameters
        state.balanceState.byNetwork[networkName].totalTokenValue =
          calculateTotalTokenValue(
            state.balanceState.byNetwork[networkName].tokens,
            state.tokensByNetwork,
            state.nativeTokensByNetwork,
            networkName
          );
      }
    },
    silentUpdateNativeBalance: (state, action) => {
      const { networkName, balance } = action.payload;

      if (state.balanceState.byNetwork[networkName]?.native) {
        state.balanceState.byNetwork[networkName].native.balance = balance;
        state.balanceState.byNetwork[networkName].native.lastUpdated =
          Date.now();

        // Recalculate total if including native
        const nativeBN = ethers.utils.parseEther(balance || "0");
        const tokensBN = ethers.utils.parseEther(
          state.balanceState.byNetwork[networkName].totalTokenValue || "0"
        );
        state.balanceState.byNetwork[networkName].totalTokenValue =
          ethers.utils.formatEther(nativeBN.add(tokensBN));
      }
    },
    setBalanceLoading: (state, action) => {
      const { networkName, tokenAddress } = action.payload;

      if (tokenAddress === "native") {
        state.balanceState.byNetwork[networkName].native.status = "loading";
      } else {
        const tokenKey = tokenAddress.toLowerCase();
        if (!state.balanceState.byNetwork[networkName].tokens[tokenKey]) {
          state.balanceState.byNetwork[networkName].tokens[tokenKey] = {
            balance: "0",
            lastUpdated: null,
            status: "loading",
          };
        } else {
          state.balanceState.byNetwork[networkName].tokens[tokenKey].status =
            "loading";
        }
      }
    },
    setBalanceError: (state, action) => {
      const { networkName, tokenAddress, error } = action.payload;

      if (tokenAddress === "native") {
        state.balanceState.byNetwork[networkName].native.status = "failed";
        state.balanceState.byNetwork[networkName].native.error = error;
      } else {
        const tokenKey = tokenAddress.toLowerCase();
        state.balanceState.byNetwork[networkName].tokens[tokenKey] = {
          ...state.balanceState.byNetwork[networkName].tokens[tokenKey],
          status: "failed",
          error,
        };
      }
    },
    addBalanceListener: (state, action) => {
      const { networkName, tokenAddress } = action.payload;

      if (!state.balanceState.listeners[networkName]) {
        state.balanceState.listeners[networkName] = { tokens: [] };
      }

      if (tokenAddress === "native") {
        state.balanceState.listeners[networkName].native = true;
      } else {
        const tokenKey = tokenAddress.toLowerCase();
        if (
          !state.balanceState.listeners[networkName].tokens.includes(tokenKey)
        ) {
          state.balanceState.listeners[networkName].tokens.push(tokenKey);
        }
      }
    },
    removeBalanceListener: (state, action) => {
      const { networkName, tokenAddress } = action.payload;

      if (tokenAddress === "native") {
        state.balanceState.listeners[networkName].native = false;
      } else {
        const tokenKey = tokenAddress.toLowerCase();
        state.balanceState.listeners[networkName].tokens =
          state.balanceState.listeners[networkName].tokens.filter(
            (addr) => addr !== tokenKey
          );
      }
    },
    updateLastFetched: (state, action) => {
      const { networkName, timestamp } = action.payload;
      state.balanceState.lastFetched = state.balanceState.lastFetched || {};
      state.balanceState.lastFetched[networkName] = timestamp;
    },
    addWebSocketProvider: (state, action) => {
      const { networkName, provider } = action.payload;
      state.websocketProviders[networkName] = provider;
    },
    removeWebSocketProvider: (state, action) => {
      const { networkName } = action.payload;
      if (state.websocketProviders[networkName]) {
        state.websocketProviders[networkName].removeAllListeners();
        delete state.websocketProviders[networkName];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeBalanceTracking.pending, (state) => {
        state.balanceLoading = true;
      })
      .addCase(initializeBalanceTracking.fulfilled, (state) => {
        state.balanceLoading = false;
      })
      .addCase(initializeBalanceTracking.rejected, (state, action) => {
        state.balanceLoading = false;
        state.balanceError = action.error.message;
      })
      .addCase(updateBalancesAfterTrade.pending, (state) => {
        state.balanceLoading = true;
      })
      .addCase(updateBalancesAfterTrade.fulfilled, (state) => {
        state.balanceLoading = false;
      })
      .addCase(updateBalancesAfterTrade.rejected, (state, action) => {
        state.balanceLoading = false;
        state.balanceError = action.error.message;
      });
  },
});

export const {
  setAssets,
  setAssetsPending,
  setAssetsFailure,
  triggerBalanceChange,
  setProviders,
  setActiveProvider,
  initNetworkBalances,
  updateTokenBalance,
  updateNativeBalance,
  silentUpdateTokenBalance,
  silentUpdateNativeBalance,
  setBalanceLoading,
  setBalanceError,
  addBalanceListener,
  removeBalanceListener,
  updateLastFetched,
  addWebSocketProvider,
  removeWebSocketProvider,
} = assetSlice.actions;

export default assetSlice.reducer;
