
import React, { useState } from "react";
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { FaArrowsToDot } from "react-icons/fa6";
import { RiSearch2Line } from "react-icons/ri";
import { FaRegEnvelope } from "react-icons/fa6";
import { IoIosNotificationsOutline } from "react-icons/io";
import { CgMenuGridO } from "react-icons/cg";
import { MdOutlineShowChart } from "react-icons/md";
import { LuChartSpline } from "react-icons/lu";
import { FaChartSimple } from "react-icons/fa6";
import { LuChartNoAxesColumnDecreasing } from "react-icons/lu";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { MdOutlineTableChart } from "react-icons/md";




const data = [
    { name: "12 Oct", price: 10000 },
    { name: "13 Oct", price: 20900 },
    { name: "14 Oct", price: 8900 },
    { name: "1 Oct", price: 20500 },
    { name: "19 Oct", price: 2500 },
    { name: "25 Oct", price: 17500 },
    { name: "27 Oct", price: 27500 },
    { name: "29 Oct", price: 7500 },
    { name: "30 Oct", price: 28000 },
    { name: "31 Oct", price: 18000 },
];

const Portfolio = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [clickFst, setClickFst] = useState("Coloumn");
    const [clickSnd, setClickSnd] = useState("24H");
    const [isOpen, setIsOpen] = useState(false);

    const handleSearch = (e) => {
        e.preventDefault();
        console.log("Searching for:", searchTerm);
    };

    const handleClick = (chart) => {
        setClickFst(chart);
    };

    const handleSnd = (Snd) => {
        setClickSnd(Snd);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="flex bg-gray-100 p-6 justify-center">

            <div className="bg-white p-6 rounded shadow-md w-full max-w-7xl">
                <header className="flex justify-between items-center mb-6  pb-4">
                    <h2 className="text-2xl font-bold">Portfolio</h2>


                </header>


                <div className="flex gap-10">
                    <div className="w-[100%]">
                        <div className="flex flex-col gap-4  mb-10">
                            <h3 className="text-lg font-bold">Bitcoin USD (BTC-USD)</h3>
                            <div className="flex items-center gap-5">
                                <p className="text-2xl font-bold">$23,097.00</p>
                                <p className="text-green-400 flex items-center gap-1 text-sm"><MdOutlineShowChart /> <span>5.9%</span></p>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2 bg-neutral-100 rounded cursor-pointer px-1 py-1">
                                    <p
                                        className={`flex items-center gap-2 py-2 px-2 ${clickFst === "Line" ? "bg-white shadow rounded py-2 px-2" : ""
                                            }`}
                                        onClick={() => handleClick("Line")}
                                    >
                                        <LuChartSpline /> Line
                                    </p>

                                    <p
                                        className={`flex items-center gap-2 py-2 px-2 ${clickFst === "Coloumn" ? "bg-white shadow rounded py-2 px-2" : ""
                                            }`}
                                        onClick={() => handleClick("Coloumn")}
                                    >
                                        <FaChartSimple /> Coloumn
                                    </p>

                                    <p
                                        className={`flex items-center gap-2 py-2 px-2 ${clickFst === "Japanesse Candles" ? "bg-white shadow rounded py-2 px-2" : ""
                                            }`}
                                        onClick={() => handleClick("Japanesse Candles")}
                                    >
                                        <LuChartNoAxesColumnDecreasing /> Japanesse Candles
                                    </p>
                                </div>

                                <div className="flex items-center gap-3 bg-neutral-100 rounded cursor-pointer px-1 py-1">
                                    <p
                                        className={`flex items-center gap-2 py-1 px-2 ${clickSnd === "24H" ? "bg-white shadow rounded py-1 px-2" : ""
                                            }`}
                                        onClick={() => handleSnd("24H")}
                                    >
                                        24H
                                    </p>

                                    <p
                                        className={`flex items-center gap-2 py-1 px-2 ${clickSnd === "1W" ? "bg-white shadow rounded py-1 px-2" : ""
                                            }`}
                                        onClick={() => handleSnd("1W")}
                                    >
                                        1W
                                    </p>

                                    <p
                                        className={`flex items-center gap-2 py-1 px-2 ${clickSnd === "1Y" ? "bg-white shadow rounded py-1 px-2" : ""
                                            }`}
                                        onClick={() => handleSnd("1Y")}
                                    >
                                        1Y
                                    </p>

                                    <p
                                        className={`flex items-center gap-2 py-1 px-2 ${clickSnd === "All" ? "bg-white shadow rounded py-1 px-2" : ""
                                            }`}
                                        onClick={() => handleSnd("All")}
                                    >
                                        All
                                    </p>
                                </div>
                            </div>
                        </div>
                        <ResponsiveContainer width="100%" height={230}>
                            <LineChart data={data}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="price" stroke="#4CAF50" strokeWidth={2} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>


                <div className="flex items-center justify-between mt-10">
                    <div>
                        <p className="text-xl font-bold">Tokens</p>
                    </div>


                    <form onSubmit={handleSearch} className="relative flex items-center gap-5">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="border border-gray-300 p-2 pl-10 pr-10 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200 w-64"
                        />
                        <FaArrowsToDot className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />

                        <button type="submit" className="absolute left-52 top-1/2 transform -translate-y-1/2 p-3">
                            <RiSearch2Line className="text-gray-500 cursor-pointer text-xl" />
                        </button>

                        <button onClick={toggleDropdown}
                            className="flex items-center gap-2 border rounded border-gray-300 cursor-pointer p-2"><RiBarChartHorizontalLine /> Filter</button>
                        {isOpen && (
                            <div className="absolute left-52 mt-[23rem] w-48 bg-white border border-gray-300 shadow shadow-blue-400 rounded-md p-2 z-10 slide-top">
                                <p className="py-1 px-2 hover:bg-gray-100 cursor-pointer">Option 1</p>
                                <p className="py-1 px-2 hover:bg-gray-100 cursor-pointer">Option 2</p>
                                <p className="py-1 px-2 hover:bg-gray-100 cursor-pointer">Option 3</p>
                            </div>
                        )}

                        <button className="flex items-center gap-2 border rounded border-gray-300 cursor-pointer p-2"><MdOutlineTableChart className="text-xl" /> Customize</button>
                        <div className="flex items-center gap-2 bg-neutral-100 rounded cursor-pointer">
                            <p><CgMenuGridO className="text-xl" /></p>
                            <p className="py-2 rounded px-2 shadow bg-white"><FaChartSimple /> </p>
                        </div>

                    </form>
                </div>


                <div className="mt-7">
                    <div className="grid grid-cols-6 bg-neutral-100 py-2 px-3 font-semibold">
                        <div>Name</div>
                        <div>Price</div>
                        <div>Balance</div>
                        <div>Market Cap</div>
                        <div>Volume (24h)</div>
                        <div>Circ Supply</div>
                    </div>
                </div>

                <div>
                    {[
                        ["Ethereum", "$34,200", "0.5 BTC", "$670 billion", "$35 billion", 75],
                        ["Bitcoin (BTC)", "$34,200", "0.5 BTC", "$670 billion", "$35 billion", 60],
                        ["Bytecoin", "$34,200", "0.5 BTC", "$670 billion", "$35 billion", 50],
                        ["Digibyte", "$34,200", "0.5 BTC", "$670 billion", "$35 billion", 20]
                    ].map((coin, index) => {
                        const progress = coin[5];

                        // Define color based on percentage
                        const progressColor =
                            progress >= 70 ? "bg-green-500" :
                                progress >= 60 ? "bg-yellow-500" :
                                    progress >= 50 ? "bg-blue-500" :
                                        "bg-red-500"; // Default for low percentages

                        return (
                            <div key={index} className="grid grid-cols-6 py-2 px-3 items-center">
                                {coin.slice(0, 5).map((item, i) => (
                                    <div key={i}>{item}</div>
                                ))}
                                <div className="flex items-center space-x-2">
                                    <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div className={`${progressColor} h-2.5 rounded-full`} style={{ width: `${progress}%` }}></div>
                                    </div>
                                    <span className="text-sm font-semibold">{progress}%</span>
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>


        </div>
    );
};

export default Portfolio;
