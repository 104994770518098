import React, { useState, useEffect, useMemo, useCallback } from "react";
import BuySell from "../BuySell/BuySell";
import {
  InformationCircleIcon,
  CheckmarkBadge01Icon,
  UnavailableIcon,
} from "hugeicons-react";
import Blockies from "react-blockies";
import { TVChartContainer } from "../../Tradingview/TVChartContainer";
import Deposit from "./DepositWithdraw/Deposit";
import { getTransactionQueryKey } from "wagmi/query";
import Withdraw from "./DepositWithdraw/Withdraw";
import { CHECK_BALANCE_IN_EXCHANGE } from "../../../services/exchange";
import { useDispatch, useSelector } from "react-redux";
import { app } from "../../../utils/config";
import Nodata from "../../../Components/Nodata/Nodata";
import Chart from "./Chart/Chart";
import CandleStickChart from "./Chart/CandleStickChart";

import Liquidity from "./Liquidity/Liquidity";
import TVL from "./Liquidity/TVL";
import { getPrice } from "./Liquidity/getPrice";
import ClipLoader from "react-spinners/ClipLoader";
import { GET_TRADE_DATA } from "../../../services/exchange";
import ReactTimeAgo from "react-time-ago";
import { GET_TICKER_ORDERS } from "../../../services/business";
import { addOrders } from "../../../Redux/feautures/orders/OrderSlice";
import { getBalanceFromAddress } from "../BuySell/helper";
import CustomLoader from "../../../Components/CustomLoader/CustomLoader";
import { numberWithCommas } from "../../../assets/js/numberWithCommas";
import { formatDecimal } from "../../../assets/js/formatDecimal";
import { socketEvents } from "../../../Sockets/events";
import { useSocket } from "../../../Sockets/contextProvider/SocketContext";
import ReactPaginate from "react-paginate";

const ExchangeTrade = ({ data, businessStatus }) => {
  const dispatch = useDispatch();
  const socket = useSocket();
  const { privateKey, wallet_address } = useSelector((state) => state.user);
  // const { trades } = useSelector((state) => state.trades);
  const { Assets, balanceChange } = useSelector((state) => state.assets);
  const address = data?.contractAddress;
  const [activeBtn, setActiveBtn] = useState("buy");
  const [addLiquidityModal, setAddLiquidityModal] = useState(false);

  const [contractBalance, setContractBalances] = useState({
    tokenA: 0,
    tokenB: 0,
  });
  const [stats, setStats] = useState({});
  const [inFlight, setInflight] = useState(true);
  // const [tokenPriceInEgax, setTokenPriceInEgax] = useState(0);
  const [tokenPriceInUSD, setTokenPriceInUSD] = useState(0);
  const [marketCapLoader, setMarketCapLoader] = useState(false);
  const [trades, setTrades] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tradesLoading, setTradesLoading] = useState(false);
  const tradesPerPage = 25;
  const toggleActiveBtn = async (e) => {
    setActiveBtn(e.currentTarget.id);
  };
  const BASE_TOKEN =
    data?.network === "EGOCHAIN"
      ? "EGAX"
      : data?.network === "BASE"
      ? "DHV"
      : "EGAX";
  // //console.log(data, "osuofia");
  const payload = {
    id: 1,
    tokenA: "04939383",
    tokenB: "04939383",
  };

  // useEffect(() => {
  //   fetchTokenBalances();
  // }, []);

  const toggleAddLiquidityModal = () => {
    setAddLiquidityModal(!addLiquidityModal);
  };

  const getTokenPrices = async () => {
    setMarketCapLoader(true);
    const res = await getPrice(data?.contractAddress, wallet_address, data);

    if (res.error) {
      console.error("Error fetching liquidity details:", res.error);
      setMarketCapLoader(false);
    } else {
      setTokenPriceInUSD(res?.tokenPriceInUsd);
    }
    setMarketCapLoader(false);
  };

  useEffect(() => {
    getTokenPrices();
  }, [data]);

  const getTrades = async (page = 1) => {
    console.log("====================================");
    console.log(page.toString(), tradesPerPage.toString());
    console.log("====================================");
    setTradesLoading(true);

    try {
      const res = await GET_TRADE_DATA({
        ticker: `${BASE_TOKEN}-${data?.tokenSymbol}`,
        page: page.toString(),
        limit: tradesPerPage.toString(),
      });

      console.log("Fetched Trades Data:", res);

      if (res?.success) {
        setTrades(res.trades || []);
        // Set total pages based on API response
        setTotalPages(res.pagination?.totalPages || 1);
      }
    } catch (error) {
      console.error("Error fetching trades:", error);
    } finally {
      setTradesLoading(false);
    }
  };

  useEffect(() => {
    getTrades(currentPage);
  }, [currentPage, data]);

  const handlePageClick = ({ selected }) => {
    console.log("====================================");
    console.log(selected);
    console.log("====================================");
    setCurrentPage(selected + 1); // Convert 0-based index to 1-based
  };
  useEffect(() => {
    const eventKey = `${socketEvents.TRADE_EVENT}/${BASE_TOKEN}-${data?.tokenSymbol}`;

    const handleTradeEvent = (tradeData) => {
      // console.log(tradeData, "TRADE SOCKET DATA");

      if (!tradeData) {
        // console.log("Trade socket emission failed!!!");
        return;
      }

      // console.log("Trade socket emission successful!!!");

      // Assign background color based on swapType
      const newTrade = {
        ...tradeData,
        highlight: tradeData.swapType === "SELL" ? "#ffc7c7" : "#d6ffe8",
      };

      // Update state with new trade
      setTrades((prevTrades) => [newTrade, ...prevTrades]);

      // Reset background after 1s
      setTimeout(() => {
        setTrades((prevTrades) =>
          prevTrades?.map((trade) =>
            trade === newTrade ? { ...trade, highlight: "#ffffff" } : trade
          )
        );
      }, 1200);
    };

    socket.on(eventKey, handleTradeEvent);

    return () => {
      socket.off(eventKey, handleTradeEvent); // Cleanup listener
    };
  }, [socket, data?.tokenSymbol]);

  console.log("====================================");
  console.log(data, BASE_TOKEN);
  console.log("====================================");

  return (
    <div
      className="ExchangeTrade_area
      "
    >
      <div className="ExchangeTrade_area_1">
        <div className="ExchangeTrade_area_1_div1">
          <div className="ExchangeTrade_area_1_div1_title">
            <div className="ExchangeTrade_area_1_div1_title_cont1">
              {data?.tokenName || "_ _"}{" "}
              <span className="tableBody_row_data_first_div_cont1_title_Span">
                {" "}
                ${data?.tokenSymbol || "_ _"}
              </span>{" "}
              <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                <Blockies
                  seed={address ? address : "0xXXXX"}
                  size={5}
                  scale={4}
                  className="blockies_icon"
                />{" "}
                {address ? (
                  <> {`${address?.slice(0, 4)}...${address?.slice(37, 42)}`}</>
                ) : (
                  <>0xXXXX</>
                )}
              </div>
            </div>{" "}
            <div className="ExchangeTrade_area_1_div1_title_cont2">
              <div className="ExchangeTrade_area_1_div1_title_cont2_title">
                Market Cap:
              </div>{" "}
              $
              {marketCapLoader ? (
                <ClipLoader color="#605afd" size={18} />
              ) : (
                <>
                  {" "}
                  {parseFloat(
                    parseFloat(tokenPriceInUSD) *
                      parseFloat(data?.initialSupply) || 0
                  )?.toFixed(4)}
                </>
              )}
            </div>
          </div>
          <div className="ExchangeTrade_area_1_div1_chart">
            {/* <TVChartContainer
              ticker={`${data?.tokenSymbol}-${app.BASE_TOKEN}`}
            /> */}
            <Chart
              ticker={`${BASE_TOKEN}-${data?.tokenSymbol}`}
              baseToken={BASE_TOKEN}
              businessData={data}
            />
            {/* <CandleStickChart
              ticker={`${app.BASE_TOKEN}-${data?.tokenSymbol}`}
            /> */}
          </div>
        </div>
        <div className="ExchangeTrade_area_1_div2">
          <BuySell
            activeBtn={activeBtn}
            flight={false}
            toggleActiveBtn={toggleActiveBtn}
            symbol={data?.tokenSymbol}
            tokenAddress={data?.contractAddress}
            ticker={`${BASE_TOKEN}-${data?.tokenSymbol}`}
            businessStatus={businessStatus}
            data={data}
            baseToken={BASE_TOKEN}
          />
          {!data ? (
            <button className="addLiquidity_btn" disabled={true}>
              Fetching data...
            </button>
          ) : (
            <button
              className="addLiquidity_btn"
              onClick={toggleAddLiquidityModal}
            >
              Add Liquidity
            </button>
          )}

          <div className="ExchangeTrade_area_1_div2_cont2">
            <div className="ExchangeTrade_area_1_div2_cont2_div">
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont1">
                {" "}
                TVL
              </div>
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont2">
                $
                <TVL
                  tokenAddress={data?.contractAddress}
                  data={data}
                  baseToken={BASE_TOKEN}
                />
              </div>
            </div>
            <div className="ExchangeTrade_area_1_div2_cont2_div">
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont1">
                {" "}
                Market cap
              </div>
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont2">
                {" "}
                $
                {marketCapLoader ? (
                  <ClipLoader color="#605afd" size={18} />
                ) : (
                  <>
                    {parseFloat(
                      parseFloat(tokenPriceInUSD) *
                        parseFloat(data?.initialSupply) || 0
                    )?.toFixed(4)}
                  </>
                )}
              </div>
            </div>
            {/* <div className="ExchangeTrade_area_1_div2_cont2_div">
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont1">
                24h Chg
              </div>
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont2">
                {parseFloat(stats?.change24 || 0)?.toFixed(4)
                  ? `$ ${parseFloat(stats?.change24 || 0)?.toFixed(4)}%`
                  : "--"}
              </div>
            </div> */}
            {/* <div className="ExchangeTrade_area_1_div2_cont2_div">
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont1">
                Market Cap
              </div>
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont2">
                {stats?.initialSupply
                  ? `$ ${
                      parseFloat(stats?.initialSupply) *
                      parseFloat(stats?.currentPrice)
                    }`
                  : "--"}
              </div>
            </div> */}
            {/* <div className="ExchangeTrade_area_1_div2_cont2_div">
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont1">
                {" "}
                Holders
              </div>
              <div className="ExchangeTrade_area_1_div2_cont2_div_cont2">
                1000
              </div>
            </div> */}
          </div>

          {data?.network === "EGOCHAIN" ? (
            <a
              href={`https://egoscan.io/address/${address}`}
              target="_blank"
              className="ExchangeTrade_area_1_div2_cont3"
            >
              Contract address:{" "}
              {address ? (
                <> {`${address?.slice(0, 4)}...${address?.slice(37, 42)}`}</>
              ) : (
                <>0xXXXX</>
              )}
            </a>
          ) : (
            <a
              href={`https://dexscreener.com/base/${address}`}
              target="_blank"
              className="ExchangeTrade_area_1_div2_cont3"
            >
              DexScreener :{" "}
              {address ? (
                <> {`${address?.slice(0, 4)}...${address?.slice(37, 42)}`}</>
              ) : (
                <>0xXXXX</>
              )}
            </a>
          )}
        </div>
      </div>
      {/* ========== trades data implement later */}
      <div className="ExchangeTrade_area_2">
        <div className="ExchangeTrade_area_2_cont1">
          <div className="ExchangeTrade_area_2_cont1_title">Trades</div>
          <div className="ExchangeTrade_area_2_cont1_body">
            {tradesLoading ? (
              <div style={{ background: "#fff", height: "300px" }}>
                <CustomLoader containerWidth={"100%"} margin={"10px 0px "} />
              </div>
            ) : (
              <>
                {trades.length === 0 ? (
                  <Nodata message={"No trades data"} />
                ) : (
                  <table className="TableCompWithDiv_cont_table">
                    <thead className="TableCompWithDiv_cont_head">
                      <tr className="TableCompWithDiv_cont_head_titles">
                        <th className="TableCompWithDiv_cont_head_titles_div TableCompWithDiv_cont_head_titles_div_first">
                          Account
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Type
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          ${BASE_TOKEN}
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          ${data?.tokenSymbol}
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Ticker
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div">
                          Date
                        </th>
                        <th className="TableCompWithDiv_cont_head_titles_div  TableCompWithDiv_cont_head_titles_div_last">
                          Transaction
                        </th>
                      </tr>
                    </thead>
                    <tbody className="tableBody">
                      {trades?.map((data) => {
                        return (
                          <tr
                            className="tableBody_row "
                            style={{ backgroundColor: data.highlight }}
                          >
                            <td className="tableBody_row_data tableBody_row_data_first  ">
                              <div className="RwaBusinessDetails_div_area_1_cont1_div3_cont1_value">
                                <Blockies
                                  seed={data?.user}
                                  size={5}
                                  scale={4}
                                  className="blockies_icon"
                                />{" "}
                                {data?.user ? (
                                  <>
                                    {" "}
                                    {`${data?.user?.slice(
                                      0,
                                      4
                                    )}...${data?.user?.slice(37, 42)}`}
                                  </>
                                ) : (
                                  <>0xXXXX</>
                                )}
                              </div>
                            </td>

                            <td className="tableBody_row_data">
                              <div
                                className="typeBuy"
                                style={{
                                  color:
                                    data?.swapType == "SELL"
                                      ? "#ff4b4b"
                                      : "#00c055",
                                }}
                              >
                                {data?.swapType}
                              </div>
                            </td>
                            <td className="tableBody_row_data">
                              {numberWithCommas(
                                formatDecimal(
                                  parseFloat(
                                    data?.swapType == "SELL"
                                      ? data?.amountOut
                                      : data?.amountIn
                                  )
                                )
                              )}
                            </td>
                            <td className="tableBody_row_data">
                              {numberWithCommas(
                                formatDecimal(
                                  parseFloat(
                                    data?.swapType == "SELL"
                                      ? data?.amountIn
                                      : data?.amountOut
                                  )
                                )
                              )}
                            </td>
                            <td className="tableBody_row_data">
                              {data?.ticker}
                            </td>
                            <td className="tableBody_row_data">
                              <ReactTimeAgo
                                date={data?.createdAt}
                                locale="en-US"
                              />
                            </td>
                            <td className="tableBody_row_data tableBody_row_data_last">
                              {" "}
                              {data?.txHash ? (
                                <>
                                  {" "}
                                  {`${data?.txHash?.slice(
                                    0,
                                    4
                                  )}...${data?.txHash?.slice(37, 42)}`}
                                </>
                              ) : (
                                <>0xXXXX</>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </>
            )}
            {/* Pagination Component */}
          </div>
          {totalPages > 1 && (
            <ReactPaginate
              pageCount={totalPages}
              onPageChange={handlePageClick}
              forcePage={currentPage - 1} // Keeps pagination in sync
              // Pagination Styling Classes
              containerClassName={"pagination"}
              previousClassName={"pagination__prev"}
              nextClassName={"pagination__next"}
              pageClassName={"pagination__page"}
              activeClassName={"pagination__active"}
              disabledClassName={"pagination__disabled"}
              breakClassName={"pagination__ellipsis"}
              // Labels
              previousLabel="«"
              nextLabel="»"
              breakLabel="..."
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
            />
          )}
        </div>
      </div>
      {/* {deposit && <Deposit symbol={"EGAX"} back={toggleDeposit} />}
      {withdraw && <Withdraw symbol={"EGAX"} back={toggleWithdraw} />} */}

      {addLiquidityModal && (
        <Liquidity
          cancel={() => setAddLiquidityModal(false)}
          ticker={`${BASE_TOKEN}-${BASE_TOKEN}`}
          data={data}
          baseToken={BASE_TOKEN}
        />
      )}
    </div>
  );
};

export default ExchangeTrade;
