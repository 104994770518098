import React, { useState, useEffect } from "react";

import "./index.css";
import { Camera02Icon, Tick01Icon } from "hugeicons-react";
import { ArrowLeft01Icon } from "hugeicons-react";
import { Link, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import toast, { Toaster } from "react-hot-toast";
import { CREATE_BUSINESS, CREATE_BUSINESS_2 } from "../../services/business";
import Select from "react-select";
import { useSocket } from "../../Sockets/contextProvider/SocketContext";
import { socketEvents } from "../../Sockets/events";
import { useDispatch, useSelector } from "react-redux";
import { updateBusinessState } from "../../Redux/feautures/user/userSlice";
import UploadWidget from "../Cloudinary/UploadWidget";
import { Cancel01Icon } from "hugeicons-react";
import WalletPop from "../../OnchainWallet/WalletPop/WalletPop";

const business_step = "business-step";
const validateStep = (currentStep) => {
  // Add your validation logic here
  return true; // Example validation result
};

const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia (Plurinational State of)", code: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Curaçao", code: "CW" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia", code: "CZ" },
  { name: "Côte d'Ivoire", code: "CI" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Greece", code: "GR" },
  { name: "Grenada", code: "GD" },
  { name: "Guatemala", code: "GT" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Honduras", code: "HN" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People's Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mexico", code: "MX" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "North Macedonia", code: "MK" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palestine, State of", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Qatar", code: "QA" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Togo", code: "TG" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States of America", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Venezuela", code: "VE" },
  { name: "Vietnam", code: "VN" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

const TokenCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { wallet_address, privateKey } = useSelector((state) => state.user);
  const { Assets, balanceState, nativeTokensByNetwork } = useSelector(
    (state) => state.assets
  );

  const socket = useSocket();
  const [imagePreview, setImagePreview] = useState(null);
  const [creatingModal, setCreatingModal] = useState(false);
  const [networkModal, setNetworkModal] = useState(true);
  const [creatingLoader, setCreatingLoader] = useState("init");
  const [preparingLoader, setPreparingLoader] = useState("init");
  const [listingLoader, setListingLoader] = useState("init");
  const [imageUrl, setImageUrl] = useState("");
  const [tickerStats, setTickerStats] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [createInit, setCreateInit] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);
  const [transactionType, setTransactionType] = useState(null);

  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [stage, setStage] = useState(
    parseInt(localStorage.getItem(business_step), 10) || 1
  );

  const creatorConfig = {
    "EGOCHAIN NETWORK": {
      currPrice: nativeTokensByNetwork["EGOCHAIN NETWORK"][0]?.currentPrice,
      symbol: "EGAX",
      creation_fee: parseFloat(
        500 / nativeTokensByNetwork["EGOCHAIN NETWORK"][0]?.currentPrice
      ).toFixed(3),
      logo: "https://res.cloudinary.com/itechsuite/image/upload/c_thumb,w_200,g_face/v1738169483/a5fckqt8vkjjdfbnb737.png",
    },
    // "DHIVE NETWORK": {
    //   currPrice: "",
    //   symbol: "DHIVE",
    //   creation_fee: 20,
    //   logo: "/White_Dhive_Icon.svg",
    // },
    "BASE NETWORK": {
      currPrice: "",
      symbol: "DHV",
      creation_fee: 0,
      logo: "/img/dhiveCoinIcon.png",
    },
  };
  const [payload, setPayload] = useState({
    businessName: "",
    businessType: "",
    businessRevenue: "",
    burnPercentage: "5",
    businessDescription: "",
    image: "",
    tokenSymbol: "",
    initialSupply: 0,
    egaxLiq: 0,
    initialBuy: 0,
  });
  const [creationFee, setCreationFee] = useState(0);
  const options = [
    { value: "Entertainment", label: "Entertainment" },
    { value: "Sports", label: "Sports" },
    { value: "Retail", label: "Retail" },
    { value: "Service", label: "Service" },
    { value: "Manufacturing", label: "Manufacturing" },
    { value: "fashion", label: "fashion" },
    { value: "Technology", label: "Technology" },
    { value: "Other", label: "Other..." },
  ];
  const options2 = [
    { value: "less than 1million", label: "less than 1million" },
    { value: "1million and above", label: "1million and above" },
    { value: "10million and above", label: "10million and above" },
    { value: "100million and above", label: "100million and above" },
    { value: "1billion and above", label: "1billion and above" },
    { value: "10billion and above", label: "10billion and above" },
    { value: "100billion and above", label: "100billion and above" },
  ];
  const countryOptions = countries.map(({ code, name }) => ({
    value: code,
    label: (
      <div className="countrySelectDiv">
        <img
          alt={code}
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
          className="countrySelectDiv_img"
        />
        <span className="countrySelectDiv_txt"> {name}</span>
      </div>
    ),
  }));

  const goToNextStep = () => {
    // Validate current step before moving forward
    if (validateStep(stage)) {
      const nextStep = stage + 1;
      setStage(nextStep);
      localStorage.setItem(business_step, nextStep);
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      // Handle standard input fields
      const { name, value } = e.target;
      if (name == "initialBuy") {
        setPayload((prevState) => ({
          ...prevState,
          [name]: parseFloat(value),
        }));
      }
      setPayload((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      // Handle react-select fields
      const { name, value } = e;
      setPayload((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const isValidated = () => {
    // console.log("mamam");
    // if (parseFloat(creationFee) > parseFloat(currEgax?.balance)) {
    //   return false;
    // }

    return true;
  };
  const handleCreationFee = (e) => {
    const { value } = e.target;

    setCreationFee(parseFloat(value));
    setPayload((prev) => ({
      ...prev,
      egaxLiq: parseFloat(creationFee),
    }));
  };

  const handleCreationFeeBlur = (e) => {
    if (e.target) {
      const { name, value } = e.target;

      // Check if the field is "burnPercentage" and the value is less than 5
      if (value <= creatorConfig[selectedNetwork]?.creation_fee) {
        setPayload((prev) => ({
          ...prev,
          egaxLiq: 0,
        }));

        setCreationFee(
          parseFloat(creatorConfig[selectedNetwork]?.creation_fee)
        );
      }

      setCreationFee(parseFloat(value));
      setPayload((prev) => ({
        ...prev,
        egaxLiq: parseFloat(creationFee),
      }));
    }
  };
  const handleBlur = (e) => {
    if (e.target) {
      const { name, value } = e.target;

      // Check if the field is "burnPercentage" and the value is less than 5
      if (name === "burnPercentage" && parseFloat(value) < 5) {
        setPayload((prevState) => ({
          ...prevState,
          [name]: "5", // Revert to "5" if the value is less than 5
        }));
      }
      if (name === "initialBuy") {
        setPayload((prevState) => ({
          ...prevState,
          [name]: parseFloat(value),
        }));
      }
    }
  };
  useEffect(() => {
    localStorage.setItem(business_step, stage);
  }, [stage]);

  const createTokenFunc = async () => {
    //set localstorage for initialization
    setOpenWallet(false);
    setCreateInit(false);
    setCreatingModal(true);
    setCreatingLoader("loading");
    const body = {
      businessName: payload.businessName,
      businessType: payload.businessType,
      businessRevenue: payload.businessRevenue,
      burnPercentage: parseFloat(payload.burnPercentage),
      businessDescription: payload.businessDescription,
      createdBy: wallet_address,
      image: imageUrl,
      tokenSymbol: payload.tokenSymbol,
      initialSupply: payload.initialSupply,
      egaxLiq: payload.egaxLiq,
      initialBuy: parseFloat(payload.initialBuy),
      network: selectedNetwork.split(" ")[0],
    };
    console.log(body, "reeee");

    let res = null;
    if (body.network == "BASE") {
      res = await CREATE_BUSINESS_2(body);
      console.log(res);
      if (!res.success) {
        toast.error(`Creating business Failed: ${res?.message} `);
        setCreateInit(true);
        setCreatingModal(false);
        // setTimeout(() => {
        //   setCreatingModal(false);
        // }, 3000);
        return;
      }

      toast.success("Business created successfully");
      setCreatingLoader("done");
      setCreatingModal(false);

      return;
    } else {
      // console.log(body, "hampton");
      res = await CREATE_BUSINESS(body);
      console.log(res);

      if (!res.success) {
        toast.error(`Creating business Failed: ${res?.message} `);
        setCreateInit(true);
        setCreatingModal(false);

        return;
      }

      toast.success("Business created successfully");
    }

    if (!res.success) {
      toast.error(`Creating business Failed: ${res?.message} `);
      setCreateInit(true);
      setCreatingModal(false);
      // setTimeout(() => {
      //   setCreatingModal(false);
      // }, 3000);
      return;
    }
  };

  // const listBusinessOnexchange = async () => {
  //   setListingLoader("loading");
  //   const res = await LIST_BUSINES_ON_EXCHANGE();
  //   console.log(res);
  //   if (res.success == false) {
  //     toast.error("Listing of Business Failed!!!");
  //     return;
  //   }
  // };

  // const AuthorizeBusinessCreation = async () => {
  //   setPreparingLoader("loading");
  //   const body = {
  //     walletAddress: wallet_address,
  //     listingPrice: "0.4",
  //     key: privateKey,
  //   };
  //   const res = await AUTHORIZE_BUSINESS(body);
  //   console.log(res);
  //   if (!res.success) {
  //     toast.error("Authorizing business Failed!!!");
  //     // setTimeout(() => {
  //     //   setCreatingModal(false);
  //     // }, 3000);

  //     return;
  //   }
  //   setPreparingLoader("done");
  //   dispatch(updateBusinessState());
  //   setTimeout(() => {
  //     window.location.href = "/business";
  //   }, 2000);
  //   toast.success("Creation and listing of business completed.");
  // };

  // console.log("====================================");
  // console.log(creatingLoader, listingLoader);
  // console.log("====================================");

  // socket.on(socketEvents.INIT_TOKEN_CREATION, (data) => {
  //   console.log("Event received:", data);
  // });

  useEffect(() => {
    socket.on(`/${wallet_address}/token`, (data) => {
      // console.log("Token ceation event ended:", data);
      if (!data?.success) {
        toast.error("Business creation Failed, Try Again!!!");
        setCreatingModal(false);
        return;
      }

      setCreatingLoader("done");
      setPreparingLoader("loading");
      goToNextStep();
    });
    return () => {
      socket.off(socketEvents.TOKEN); // Cleanup listener
    };
  }, [socket]);
  useEffect(() => {
    socket.on(`${wallet_address}${socketEvents.CREATE_PAIR}`, (data) => {
      // console.log("Token ceation event ended:", data);
      if (!data?.success) {
        setCreatingModal(false);
        toast.error("Pair creation failed, Try Again!!!");
        return;
      }
      setPreparingLoader("done");
      setListingLoader("loading");
      goToNextStep();
      toast.success("creation of pair successful!!!");
    });
    return () => {
      socket.off(`${wallet_address}${socketEvents.CREATE_PAIR}`); // Cleanup listener
    };
  }, [socket]);

  useEffect(() => {
    socket.on(
      `${wallet_address}${socketEvents.ADD_INITIAL_LIQUIDITY}`,
      (data) => {
        // console.log("Token ceation event ended:", data);
        if (!data?.success) {
          setCreatingModal(false);
          toast.error("Adding liquidity failed, Try Again!!!");
          return;
        }
        setListingLoader("done");
        toast.success("Adding liquidity successful!!!");
        setTimeout(() => {
          window.location.href = "/business";
        }, 3000);
      }
    );
    return () => {
      socket.off(`${wallet_address}${socketEvents.ADD_INITIAL_LIQUIDITY}`); // Cleanup listener
    };
  }, [socket]);

  const handleEvent = (err, result) => {
    // console.log(result, "ama");
    if (result?.event == "success") {
      const image = result?.info?.url;
      setImageUrl(image);
      setImagePreview(image);
      return;
    }
  };

  const toggleInitCreateInit = () => {
    setCreateInit((prev) => !prev);
  };

  const initCreation = () => {
    // console.log(payload);
    setOpenWallet(true);
    setTransactionType("create");
    setCreateInit(false);
    // setInitLoading(true);
  };

  const handleNetworkSelect = async () => {
    setNetworkModal(false);
  };

  useEffect(() => {
    if (selectedNetwork === "EGOCHAIN NETWORK") {
      setCreationFee(creatorConfig[selectedNetwork]?.creation_fee);
      // setCreationFee(parseFloat(500 / currEgax?.currentPrice).toFixed(3));
      return;
    }
    if (selectedNetwork === "BASE NETWORK") {
      setCreationFee(0);
      // setCreationFee(parseFloat(500 / currEgax?.currentPrice).toFixed(3));
      return;
    }
  }, [selectedNetwork]);

  return (
    <>
      <div className="TokenCreationDiv_area">
        <div className="ListModal_cont_area_back_button">
          {" "}
          <div
            className="ListModal_cont_area_back_button_div1"
            onClick={() => {
              navigate("/business");
            }}
          >
            <ArrowLeft01Icon />
            Back
          </div>
        </div>
        <div className="TokenCreationDiv_area_header">
          Create New RWA Business Token (New Token)
          <div className="TokenCreationDiv_area_header_para">
            Your business will be created and listed on dhive exchange for
            trading.
          </div>
        </div>

        <div className="TokenCreationDiv_area_body">
          <div className="TokenCreationDiv_area_body_title">Token Details</div>
          <div className="TokenCreationDiv_area_body_div1">
            <div className="seemore_div_cont_upload_div1">
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="seemore_div_cont_upload_div1_img"
                />
              ) : (
                <UploadWidget event={handleEvent} />
              )}
            </div>
            <div className="TokenCreationDiv_area_body_pic_div1_2">
              <div className="TokenCreationDiv_area_body_pic_div1_2_cont1">
                {" "}
                Business
              </div>{" "}
              <div className="TokenCreationDiv_area_body_pic_div1_2_cont2">
                {" "}
                Profile Picture <span className="asteriks">*</span>
              </div>{" "}
            </div>
          </div>
          {/* ==== */}
          {/* ==== */}
          {/* ==== */}
          {/* ==== */}
          <div className="TokenCreationDiv_area_body_div2">
            <div className="TokenCreationDiv_area_body_div2_title">
              Business Name <span className="asteriks">*</span>
            </div>
            <input
              type="text"
              placeholder=" Name"
              name="businessName"
              value={payload.businessName}
              onChange={handleChange}
              className="TokenCreationDiv_area_body_div2_input"
            />
          </div>
          <div className="TokenCreationDiv_area_body_div2">
            <div className="TokenCreationDiv_area_body_div2_title">
              Token Symbol <span className="asteriks">*</span>{" "}
              <span className="egspan">
                (e.g if business name is John Doe token symbol should be "JD" in
                caps)
              </span>
            </div>
            <input
              type="text"
              placeholder="Ticker"
              name="tokenSymbol"
              value={payload.tokenSymbol}
              onChange={handleChange}
              className="TokenCreationDiv_area_body_div2_input"
            />
          </div>
          <div className="TokenCreationDiv_area_body_div2">
            <div className="TokenCreationDiv_area_body_div2_title">
              Token Supply <span className="asteriks">*</span>{" "}
              <span className="egspan"></span>
            </div>
            <input
              type="number"
              placeholder="0"
              name="initialSupply"
              value={payload.initialSupply}
              onChange={handleChange}
              className="TokenCreationDiv_area_body_div2_input"
            />
          </div>
          {/* <div className="TokenCreationDiv_area_body_div2">
            <div className="TokenCreationDiv_area_body_div2_title">
              Token Liquidity <span className="asteriks">*</span>{" "}
              <span className="egspan">
                (e.g if business name is John Doe token symbol should be "JD" in
                caps)
              </span>
            </div>
            <input
              type="number"
              placeholder="0"
              name="egaxLiq"
              value={payload.egaxLiq}
              onChange={handleChange}
              className="TokenCreationDiv_area_body_div2_input"
            />
          </div> */}
          <div className="TokenCreationDiv_area_body_div2">
            <div className="TokenCreationDiv_area_body_div2_title">
              Product purchase cash back<span className="asteriks">*</span>{" "}
              <span className="egspan">
                (A certain percentage ranging from 5% - 15% of your token
                supply.)
              </span>
            </div>
            <input
              type="number"
              placeholder="0"
              name="burnPercentage"
              value={payload.burnPercentage}
              onChange={handleChange}
              onBlur={handleBlur} // Add this
              className="TokenCreationDiv_area_body_div2_input"
            />
          </div>
          <div className="TokenCreationDiv_area_body_div2">
            <div className="TokenCreationDiv_area_body_div2_title">
              Business Type <span className="asteriks">*</span>
            </div>

            <Select
              defaultValue={options.find(
                (option) => option.value === payload.businessType
              )}
              onChange={(selectedOption) =>
                handleChange({
                  name: "businessType",
                  value: selectedOption.value,
                })
              }
              options={options}
              className="TokenCreationDiv_area_body_div2_select2"
            />
          </div>
          <div className="TokenCreationDiv_area_body_div2">
            <div className="TokenCreationDiv_area_body_div2_title">
              Business Revenue <span className="asteriks">*</span>
            </div>

            <Select
              defaultValue={options2.find(
                (option) => option.value === payload.businessRevenue
              )}
              onChange={(selectedOption) =>
                handleChange({
                  name: "businessRevenue",
                  value: selectedOption.value,
                })
              }
              options={options2}
              className="TokenCreationDiv_area_body_div2_select2"
            />
          </div>
          <div className="TokenCreationDiv_area_body_div2">
            <div className="TokenCreationDiv_area_body_div2_title">
              Business Location <span className="asteriks">*</span>
            </div>

            <Select
              // defaultValue={countryOptions.find(
              //   (option) => option.value === payload.businessRevenue
              // )}
              // onChange={(selectedOption) =>
              //   handleChange({
              //     name: "businessRevenue",
              //     value: selectedOption.value,
              //   })
              // }
              options={countryOptions}
              className="TokenCreationDiv_area_body_div2_select2"
            />
          </div>
          <div className="TokenCreationDiv_area_body_div2">
            <div className="TokenCreationDiv_area_body_div2_title">
              Business Description <span className="asteriks">*</span>
            </div>
            <textarea
              cols="30"
              rows="10"
              name="businessDescription"
              value={payload.businessDescription}
              onChange={handleChange}
              className="TokenCreationDiv_area_body_div2_text_area"
            ></textarea>
          </div>
          {!wallet_address ? (
            <button
              className="TokenCreationDiv_area_button"
              onClick={() => navigate("/wallet")}
            >
              Create wallet
            </button>
          ) : (
            <button
              className="TokenCreationDiv_area_button"
              onClick={toggleInitCreateInit}
              // onClick={createTokenFunc}
            >
              Create and List Business
            </button>
          )}
        </div>
      </div>

      {networkModal && (
        <div className="creatingModalDiv" style={{ padding: "10px" }}>
          <div className="creatingModalDiv_container">
            <div className="creatingModalDiv_container_title">
              Select Chain to Deploy
            </div>

            <div className="creatingModalDiv_container_para">
              Choose a Network to deploy business to
            </div>

            <div className="space-y-4">
              {[
                { name: "BASE NETWORK", img: "/img/baseLogo.png" },
                {
                  name: "EGOCHAIN NETWORK",
                  img: "https://res.cloudinary.com/itechsuite/image/upload/c_thumb,w_200,g_face/v1738169483/a5fckqt8vkjjdfbnb737.png",
                },
                // { name: "DHIVE NETWORK", img: "/White_Dhive_Icon.svg" },
              ].map((network) => (
                <label
                  key={network.name}
                  className={`flex items-center p-4 border rounded-lg cursor-pointer transition-all ${
                    selectedNetwork === network.name
                      ? "border-blue-500 bg-blue-50"
                      : "border-gray-300"
                  }`}
                >
                  <img
                    src={network.img}
                    alt={network.name}
                    className="w-10 h-10 mr-4"
                  />

                  <span className="text-lg font-medium">{network.name}</span>
                  <input
                    type="radio"
                    name="network"
                    value={network.name}
                    checked={selectedNetwork === network.name}
                    onChange={(e) => setSelectedNetwork(e.target.value)}
                    className="ml-auto hidden"
                  />
                </label>
              ))}
            </div>
            <button
              // onClick={() => setNetworkModal(false)}
              onClick={handleNetworkSelect}
              className="mt-6 w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition"
            >
              Proceed
            </button>
          </div>
        </div>
      )}

      {creatingModal && (
        <div className="creatingModalDiv" style={{ padding: "10px" }}>
          {selectedNetwork.split(" ")[0] === "BASE" ? (
            <>
              <div className="creatingModalDiv_container">
                <div className="creatingModalDiv_container_title">
                  Business creation in progress
                </div>
                <div className="creatingModalDiv_container_loaders">
                  <div className="creatingModalDiv_container_loaders_div1">
                    {creatingLoader === "loading" ? (
                      <>
                        <div className="creatingModalDiv_container_loaders_div1_title">
                          Creating Business...
                        </div>
                        <div className="creatingModalDiv_container_loaders_div1_iocn_div">
                          <ClipLoader color="#6059fd" size={18} />
                        </div>
                      </>
                    ) : creatingLoader === "done" ? (
                      <>
                        <div className="creatingModalDiv_container_loaders_div1_title">
                          Business Creation Done. Awaiting Approval
                        </div>
                        <div
                          className="creatingModalDiv_container_loaders_div1_iocn_div"
                          style={{ background: "#6059fd" }}
                        >
                          <Tick01Icon size={24} />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="creatingModalDiv_container_loaders_div1_title"
                          style={{ color: "#625c71" }}
                        >
                          Tokenize Business
                        </div>
                        <div className="creatingModalDiv_container_loaders_div1_iocn_div"></div>
                      </>
                    )}
                  </div>
                </div>
                <div className="creatingModalDiv_container_para">
                  please wait this may take 3 to 5 minutes.
                </div>
              </div>
            </>
          ) : (
            <div className="creatingModalDiv_container">
              <div className="creatingModalDiv_container_title">
                Business creation in progress
              </div>
              <div className="creatingModalDiv_container_loaders">
                <div className="creatingModalDiv_container_loaders_div1">
                  {creatingLoader === "loading" ? (
                    <>
                      <div className="creatingModalDiv_container_loaders_div1_title">
                        Creating Business...
                      </div>
                      <div className="creatingModalDiv_container_loaders_div1_iocn_div">
                        <ClipLoader color="#6059fd" size={18} />
                      </div>
                    </>
                  ) : creatingLoader === "done" ? (
                    <>
                      <div className="creatingModalDiv_container_loaders_div1_title">
                        Business Creation Done. Awaiting Approval
                      </div>
                      <div
                        className="creatingModalDiv_container_loaders_div1_iocn_div"
                        style={{ background: "#6059fd" }}
                      >
                        <Tick01Icon size={24} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="creatingModalDiv_container_loaders_div1_title"
                        style={{ color: "#625c71" }}
                      >
                        Tokenize Business
                      </div>
                      <div className="creatingModalDiv_container_loaders_div1_iocn_div"></div>
                    </>
                  )}
                </div>

                {creatingLoader === "loading" ? (
                  <div className="creatingModalDiv_container_loaders_line"></div>
                ) : creatingLoader === "done" ? (
                  <div
                    className="creatingModalDiv_container_loaders_line"
                    style={{ background: "#6059fd" }}
                  ></div>
                ) : (
                  <div className="creatingModalDiv_container_loaders_line"></div>
                )}

                {/* ==== */}
                {/* ==== */}
                {/* ==== */}
                <div className="creatingModalDiv_container_loaders_div2">
                  {preparingLoader === "loading" ? (
                    <>
                      <div className="creatingModalDiv_container_loaders_div1_title">
                        Creating pair..
                      </div>
                      <div className="creatingModalDiv_container_loaders_div1_iocn_div">
                        <ClipLoader color="#6059fd" size={18} />
                      </div>
                    </>
                  ) : preparingLoader === "done" ? (
                    <>
                      <div className="creatingModalDiv_container_loaders_div1_title">
                        Pair created
                      </div>
                      <div
                        className="creatingModalDiv_container_loaders_div1_iocn_div"
                        style={{ background: "#6059fd" }}
                      >
                        <Tick01Icon size={24} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="creatingModalDiv_container_loaders_div1_title"
                        style={{ color: "#625c71" }}
                      >
                        Create Pair
                      </div>
                      <div className="creatingModalDiv_container_loaders_div1_iocn_div"></div>
                    </>
                  )}
                </div>
                {preparingLoader === "loading" ? (
                  <div className="creatingModalDiv_container_loaders_line"></div>
                ) : preparingLoader === "done" ? (
                  <div
                    className="creatingModalDiv_container_loaders_line"
                    style={{ background: "#6059fd" }}
                  ></div>
                ) : (
                  <div className="creatingModalDiv_container_loaders_line"></div>
                )}
                {/* ==== */}
                {/* ==== */}
                {/* ==== */}
                {/* ==== */}
                {/* ==== */}
                {/* ==== */}
                {/* ==== */}
                {/* ==== */}
                {/* ==== */}
                <div className="creatingModalDiv_container_loaders_div2">
                  {listingLoader === "loading" ? (
                    <>
                      <div className="creatingModalDiv_container_loaders_div1_title">
                        Adding liquidity...
                      </div>
                      <div className="creatingModalDiv_container_loaders_div1_iocn_div">
                        <ClipLoader color="#6059fd" size={18} />
                      </div>
                    </>
                  ) : listingLoader === "done" ? (
                    <>
                      <div className="creatingModalDiv_container_loaders_div1_title">
                        Liquidity added
                      </div>
                      <div
                        className="creatingModalDiv_container_loaders_div1_iocn_div"
                        style={{ background: "#6059fd" }}
                      >
                        <Tick01Icon size={24} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="creatingModalDiv_container_loaders_div1_title"
                        style={{ color: "#625c71" }}
                      >
                        Add liquidity
                      </div>
                      <div className="creatingModalDiv_container_loaders_div1_iocn_div"></div>
                    </>
                  )}
                </div>
              </div>
              <div className="creatingModalDiv_container_para">
                please wait this may take 3 to 5 minutes.
              </div>
            </div>
          )}
        </div>
      )}

      <Toaster />

      {createInit && (
        <div className="confrimBuySellDiv">
          <div className="confrimBuySellDiv_container">
            <div className="confrimBuySellDiv_title">
              Creation Summary{" "}
              <Cancel01Icon
                size={20}
                className="confrimBuySellDiv_title_icon"
                onClick={() => {
                  setCreateInit(false);
                }}
              />
            </div>
            <div className="confrimBuySellDiv_body">
              {selectedNetwork === "EGOCHAIN NETWORK" && (
                <div className="confrimBuySellDiv_body_div">
                  <div className="TokenCreationDiv_area_body_div2_title">
                    Business Creation Fee <span className="asteriks">*</span>{" "}
                  </div>

                  <div className="egspan">
                    Business creation fee ranges from a minimum of{"  "}
                    {creatorConfig[selectedNetwork]?.creation_fee} egax to a
                    maximum of 10,000,000 egax
                  </div>
                  <div className="confrimBuySellDiv_body_cont1_body">
                    <div className="TokenCreationDiv_area_body_div2_input">
                      <input
                        type="number"
                        className="confrimBuySellDiv_body_cont1_body_inputDiv_input"
                        placeholder={`0.00 ${creatorConfig[selectedNetwork]?.symbol}`}
                        name="egaxLiq"
                        value={creationFee}
                        onBlur={handleCreationFeeBlur}
                        // onChange={(e) => setCreationFee(e.target.value)}
                        onChange={handleCreationFee}
                      />
                      <img
                        src={creatorConfig[selectedNetwork]?.logo}
                        alt=""
                        className="confrimBuySellDiv_body_cont1_body_inputDiv_img"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="confrimBuySellDiv_body_div">
                <div className="TokenCreationDiv_area_body_div2_title">
                  {/* Buy {payload.tokenSymbol} */}
                  Buy {payload.tokenSymbol}
                  {/* <span className="asteriks">*</span>{" "} */}
                </div>
                <div className="egspan">
                  Purchasing a small amount of your token is optional but can
                  help protect your business from sniper bots.
                </div>
                <div className="confrimBuySellDiv_body_cont1_body">
                  <div className="TokenCreationDiv_area_body_div2_input">
                    <input
                      type="number"
                      className="confrimBuySellDiv_body_cont1_body_inputDiv_input"
                      placeholder={`0.00 ${creatorConfig[selectedNetwork]?.symbol}`}
                      name="initialBuy"
                      value={payload?.initialBuy}
                      onChange={handleChange}
                    />
                    <div className="confrimBuySellDiv_body_cont1_body_inputDiv_img_div">
                      <img
                        src={creatorConfig[selectedNetwork]?.logo}
                        alt=""
                        className="confrimBuySellDiv_body_cont1_body_inputDiv_img"
                      />
                      {selectedNetwork === "BASE NETWORK" && (
                        <img
                          src="/img/baseLogo.png"
                          alt=""
                          className="confrimBuySellDiv_body_cont1_body_inputDiv_img_span"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="TokenCreationDiv_area_body_div2_title">
                Summary
              </div> */}
              <div className="confrimBuySellDiv_body2">
                <div className="swap_details_div_body_cont1">
                  <div className="swap_details_div_body_cont1_div1">
                    Business Creation Fee
                  </div>
                  <div className="swap_details_div_body_cont1_div2">
                    {/* {creationFee ||
                      parseFloat(500 / currEgax?.currentPrice).toFixed(3)} */}
                    {creatorConfig[selectedNetwork]?.creation_fee}{" "}
                    {creatorConfig[selectedNetwork]?.symbol}
                  </div>
                </div>
                <div className="swap_details_div_body_cont1">
                  <div className="swap_details_div_body_cont1_div1">
                    Your Initial Buy
                  </div>
                  <div className="swap_details_div_body_cont1_div2">
                    {payload?.initialBuy || 0}{" "}
                    {creatorConfig[selectedNetwork]?.symbol}
                  </div>
                </div>
                <div className="swap_details_div_body_cont1">
                  <div
                    className="swap_details_div_body_cont1_div1"
                    style={{
                      textDecoration: "none",
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    Total
                  </div>
                  <div
                    className="swap_details_div_body_cont1_div2"
                    style={{
                      textDecoration: "none",
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    {parseFloat(
                      parseFloat(payload?.initialBuy || 0) +
                        parseFloat(creationFee || 0)
                    )}{" "}
                    {creatorConfig[selectedNetwork]?.symbol}
                  </div>
                </div>
              </div>
              <div className="product_detail_cont_div5_7_btn_div">
                <button
                  className="product_detail_cont_div5_7_btn1"
                  onClick={toggleInitCreateInit}
                >
                  Cancel
                </button>
                {0 < creationFee ? (
                  <button className="product_detail_cont_div5_7_btn" disabled>
                    Insufficient balance
                  </button>
                ) : (
                  <button
                    className="product_detail_cont_div5_7_btn"
                    onClick={initCreation}
                  >
                    Create Business
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {openWallet && transactionType === "create" && (
        <WalletPop
          closePop={() => {
            setOpenWallet(false);
            setCreateInit(true);
          }}
          transaction={true}
          transactionType={transactionType}
          transact={createTokenFunc}
          loading={false}
          tokenName={"Egax"}
        />
      )}
    </>
  );
};

export default TokenCreation;
