import React, { useState, useEffect } from "react";
import { AreaChart, Area, YAxis, ResponsiveContainer } from "recharts";
import "./index.css";
import CustomLoader from "../../../../Components/CustomLoader/CustomLoader";
import { useSelector, useDispatch } from "react-redux";
import { fetchChartData } from "../../../../Redux/feautures/Chart/chartSlice";

const MiniChart = ({ ticker }) => {
  const dispatch = useDispatch();
  const { charts, loading } = useSelector((state) => state.chart);
  const data = charts[ticker] || [];

  const hasData = data?.length > 0;

  useEffect(() => {
    try {
      if (ticker) {
        dispatch(fetchChartData(ticker)); // Always fetch data in the background
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [ticker, dispatch]);

  // console.log(data);

  const getMinPrice = () => {
    try {
      if (!data) {
        return;
      }
      Math.min(...(data?.map((d) => d?.value) || 0));
    } catch (error) {
      console.log(error);
    }
  };
  const getMaxPrice = () => {
    try {
      if (!data) {
        return;
      }
      Math.max(...(data?.map((d) => d?.value) || 0));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="MinichartDiv">
      {!hasData && loading ? ( // Show loader only if no data and loading
        <CustomLoader borderSize="2px" height="25px" width="25px" />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="1%" stopColor="#6059fd" stopOpacity={0.3} />
                <stop offset="100%" stopColor="#6059fd" stopOpacity={0} />
              </linearGradient>
            </defs>

            <YAxis domain={[getMinPrice(), getMaxPrice()]} hide />

            <Area
              type="monotone"
              dataKey="value"
              stroke="#6059fd"
              fill="url(#colorUv)"
              strokeWidth={1}
              dot={false}
              isAnimationActive
              animationDuration={1000}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default MiniChart;
