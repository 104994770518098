import apiInstance from "../utils/axiosInstance";
import {
  ALL_BUSINESS_ROUTE,
  CREATE_BUSINESS_ROUTE,
  GET_ALL_TRADE_DATA_ROUTE,
  GET_BUSINESS_INFO_ROUTE,
  LIST_ON_BOT_MARKETPLACE_ROUTE,
  PUBLIC_BUSINESS_INFO_ROUTE,
  GET_24_HR_STAT_ROUTE,
  GET_ALL_BUSINESS_STAT_ROUTE,
  GET_ORDER_BY_BUSINESS_ID,
  GET_ALL_ORDER_ROUTES,
  GET_CHART_DATA_ROUTE,
  INITIATE_SWAP_ROUTES,
  UPDATE_ORDER_STATUS_ROUTE,
  EDIT_LISTED_PRODUCT_ROUTE,
  TRIGGER_NEW_BUSINESS_ROUTE,
  CREATE_BUSINESS_2_ROUTE,
} from "./routes";

export const CREATE_BUSINESS = async (payload) => {
  try {
    const res = await apiInstance.post(CREATE_BUSINESS_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const CREATE_BUSINESS_2 = async (payload) => {
  try {
    const res = await apiInstance.post(CREATE_BUSINESS_2_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};

// export const LIST_BUSINES_ON_EXCHANGE = async () => {
//   try {
//     const res = await apiInstance.get(LIST_BUSINESS_ON_EXCHANGE_ROUTE);
//     return res.data;
//   } catch (error) {
//     return error?.response?.data || error.message;
//   }
// };
export const LIST_ON_BOT_MARKETPLACE = async (payload) => {
  try {
    const res = await apiInstance.post(LIST_ON_BOT_MARKETPLACE_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const EDIT_LISTED_PRODUCT = async (payload) => {
  try {
    const res = await apiInstance.post(EDIT_LISTED_PRODUCT_ROUTE, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_BUSINESS_INFO = async ({ address }) => {
  try {
    const res = await apiInstance.get(`${GET_BUSINESS_INFO_ROUTE}/${address}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};

export const GET_ORDER_BY_PRODUCT_BUSINESS_ID = async (prodId, businessId) => {
  try {
    const res = await apiInstance.get(
      `${GET_ORDER_BY_BUSINESS_ID}productId=${prodId}&businessId=${businessId}`
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const UPDATE_ORDER_STATUS = async (
  prodId,
  businessId,
  orderId,
  status
) => {
  try {
    const res = await apiInstance.get(
      `${UPDATE_ORDER_STATUS_ROUTE}productId=${prodId}&businessId=${businessId}&orderId=${orderId}&status=${status}`
    );
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_TRADES_DATA = async () => {
  try {
    const res = await apiInstance.get(`${GET_ALL_TRADE_DATA_ROUTE}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_TICKER_ORDERS = async ({ ticker }) => {
  try {
    const res = await apiInstance.get(`${GET_ALL_ORDER_ROUTES}/${ticker}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_CHART_DATA = async ({ ticker, timeframe, page, limit }) => {
  try {
    const res = await apiInstance.get(
      `${GET_CHART_DATA_ROUTE}?ticker=${ticker}&timeFrame=${timeframe}&page=${page}&limit=${limit}`
    );
    //console.log(res.data.data, "await");
    return res?.data?.data?.prices?.map((price) => ({
      date: new Date(price[0]).toLocaleDateString(),
      value: price[1],
    }));
    // return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const GET_24_STAT = async () => {
  try {
    const res = await apiInstance.get(`${GET_24_HR_STAT_ROUTE}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const ALL_BUSINESS_STAT = async () => {
  try {
    const res = await apiInstance.get(`${GET_ALL_BUSINESS_STAT_ROUTE}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const SHOW_ALL_BUSINESS = async () => {
  try {
    const res = await apiInstance.get(`${ALL_BUSINESS_ROUTE}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const PUBLIC_BUSINESS_INFO = async ({ id }) => {
  try {
    const res = await apiInstance.get(`${PUBLIC_BUSINESS_INFO_ROUTE}/${id}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
export const INITIATE_SWAP = async (payload = { pair, txHash }) => {
  try {
    const res = await apiInstance.post(`${INITIATE_SWAP_ROUTES}`, payload);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};

export const TRIGGER_NEW_BUSINESS = async () => {
  try {
    const res = await apiInstance.get(`${TRIGGER_NEW_BUSINESS_ROUTE}`);
    return res.data;
  } catch (error) {
    return error?.response?.data || error.message;
  }
};
