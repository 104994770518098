import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.jsx";
import Exchange from "./Exchange/Exchange";
// import ExchangeTrade from "./Exchange/ExchangePages/ExchangeTrade/ExchangeTrade";
import ExchangeTradeHome from "./Exchange/ExchangePages/ExchangeTrade/ExchangeTradeHome";
import OnchainWallet from "./OnchainWallet/OnchainWallet";
import RwaBusiness from "./BusinessCreation/RWABusiness/RwaBusiness";
import TokenCreation from "./BusinessCreation/ExchangeTokenCreation/TokenCreation";
import RwaBusinessDetails from "./BusinessCreation/RWABusiness/RwaBusinessDetails";
import RwaHome from "./BusinessCreation/RWABusiness/RwaHome";
import ExchangeOrder from "./Exchange/ExchangePages/ExchangeTrade/ExchangeOrder/ExchangeOrder";
import RwaProducts from "./BusinessCreation/RWABusiness/RwaProducts/RwaProducts";
import RwaProductDetails from "./BusinessCreation/RWABusiness/RwaProducts/RwaProductDetails";
import LegalPolicy from "./Exchange/LegalPolicy/LegalPolicy";
import TradingCompetition from "./Exchange/ExchangePages/TradingCompetition/TradingCompetition";
import Market from "./Exchange/MarketPlace/Market.jsx";
import MarketPlace from "./Exchange/MarketPlace/MarketPlace";
import ProductDetail from "./Exchange/MarketPlace/MarketPlacePages/ProductDetail/ProductDetail.jsx";
import BusinessProducts from "./Exchange/MarketPlace/MarketPlacePages/BusinessProducts/BusinessProducts.jsx";
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistor, store } from "./Redux/app/store";
import WagmiCustomProvider from "./services/wagmi/WagmiCustomProvider.jsx";
import { SocketProvider } from "./Sockets/contextProvider/SocketContext";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en";
import ru from "javascript-time-ago/locale/ru";
import ImportWallet from "./OnchainWallet/ImportWallet.jsx";
import * as Sentry from "@sentry/react";
import CategoryProductsDisplay from "./Exchange/MarketPlace/MarketPlacePages/CategoryProductsDisplay/CategoryProductsDisplay.jsx";
import SearchResults from "./Exchange/MarketPlace/MarketPlacePages/SearchResults/SearchResults.jsx";
import Portfolio from "./Portfolio/Portfolio.jsx";
import WhitePaper from "./Exchange/WhitePaper/Whitepaper.jsx";

Sentry.init({
  dsn: "https://87a0e841608b10d80bd82f33472b60ed@o1296149.ingest.us.sentry.io/4508964862689280",
});

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<App />}>
        <Route path="" element={<Exchange />} />
        <Route path="legal" element={<LegalPolicy />} />
        <Route path="whitepaper" element={<WhitePaper />} />
        <Route path="tradeevent" element={<TradingCompetition />} />
        <Route path="portfolio" element={<Portfolio />} />

        <Route
          path="business/trade/:businessName"
          element={<ExchangeTradeHome />}
        />

        <Route path="market" element={<Market />}>
          <Route path="" element={<MarketPlace />} />
          <Route path="detail/:id/:category" element={<ProductDetail />} />
          <Route
            path="business/all/products/:businessName"
            element={<BusinessProducts />}
          />
          <Route
            path="category/all/products/:category"
            element={<CategoryProductsDisplay />}
          />
          <Route path="search/results" element={<SearchResults />} />
          <Route path="myOrders" element={<ExchangeOrder />} />
        </Route>
        <Route path="business" element={<RwaBusiness />}>
          <Route path="" element={<RwaHome />} />
          <Route path="create" element={<TokenCreation />} />
          <Route path="details" element={<RwaBusinessDetails />} />
          <Route path="products" element={<RwaProducts />} />
          <Route
            path="products/details/:prodId/:businessId"
            element={<RwaProductDetails />}
          />
        </Route>
        <Route path="wallet" element={<OnchainWallet />} />
        <Route path="import" element={<ImportWallet />} />
      </Route>
    </>
  )
);
createRoot(document.getElementById("root")).render(
  <StrictMode>
    <SocketProvider>
      {/* <WagmiCustomProvider> */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
      {/* </WagmiCustomProvider> */}
    </SocketProvider>
  </StrictMode>
);
