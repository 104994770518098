import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import "./Exchange.css";
import {
  InformationCircleIcon,
  CheckmarkBadge01Icon,
  AlertDiamondIcon,
  UnavailableIcon,
} from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import Blockies from "react-blockies";
import { useSelector, useDispatch } from "react-redux";
import Nodata from "../Components/Nodata/Nodata";
import ReactTimeAgo from "react-time-ago";
import { walletSplliter } from "../helper/walletSplit";
import TVL from "./ExchangePages/ExchangeTrade/Liquidity/TVL";
import { AddressShortener } from "../utils/AddressShortener";
import MarketCap, {
  Price,
} from "./ExchangePages/ExchangeTrade/Liquidity/MarketCap";
import MiniChart from "./ExchangePages/ExchangeTrade/Chart/MiniChart";
import { app } from "../utils/config";
import { GET_TRADE_DATA } from "../services/exchange";
import CustomLoader from "../Components/CustomLoader/CustomLoader";
import Select from "react-select";
import { PreferenceHorizontalIcon } from "hugeicons-react";
import FormatNumber from "../helper/formatNumber";
import { formatDecimal } from "../assets/js/formatDecimal";
import { numberWithCommas } from "../assets/js/numberWithCommas";
import { Alert02Icon } from "hugeicons-react";
import { socketEvents } from "../Sockets/events";
import { useSocket } from "../Sockets/contextProvider/SocketContext";
import { TRIGGER_NEW_BUSINESS } from "../services/business";
import { populateBusinesses } from "../Redux/feautures/businesses/businessSlice";
import { fetchTradesData } from "../Redux/feautures/trades/tradeDataSlice";
// ===
// ===
const options = [
  { value: "marketCap", label: "Sort by: Market Cap" },
  { value: "price", label: "Sort by: Price" },
  { value: "new", label: "Sort by: New" },
  { value: "verified", label: "Sort by: Verified" },
];

// Custom debounce function
const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), delay);
  };
};

// =====
// =====
// =====
// =====
// =====
const TradeRow = ({ trade }) => (
  <div className="tableBody_row_data_trades_div_cont1">
    <div className="tableBody_row_data_trades_div_cont1_div1">
      {walletSplliter({ address: trade?.user })}
    </div>
    <div className="tableBody_row_data_trades_div_cont1_div2">
      <span
        className={`${
          trade.swapType === "BUY"
            ? "tableBody_row_data_trades_div_cont1_div2_buy"
            : "tableBody_row_data_trades_div_cont1_div2_sell"
        }`}
      >
        {trade?.swapType}
      </span>
    </div>
    <div className="tableBody_row_data_trades_div_cont1_div3">
      <ReactTimeAgo date={trade?.createdAt} locale="en-US" />
    </div>
  </div>
);

// =====
// =====

// ===
// ===
// ===
// ===
const Exchange = () => {
  const dispatch = useDispatch();
  const { business_info } = useSelector((state) => state.user);
  const { businesses, businessChain } = useSelector(
    (state) => state.businesses
  );

  const tradesData = useSelector((state) => state.tradesData?.tradesData || {});
  const loading = useSelector((state) => state.tradesData?.loading || {});
  const socket = useSocket();
  const [selectedSort, setSelectedSort] = useState("new");
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleCount, setVisibleCount] = useState(15); // Lazy loading step
  const [loadingMore, setLoadingMore] = useState(false); // NEW: Loading state
  const [newlyAdded, setNewlyAdded] = useState(new Set());
  const [welcomeModal, setWelcomeModal] = useState(false);
  const navigate = useNavigate();
  // Proper useEffect implementation with all necessary checks
  useEffect(() => {
    // Early return if no businesses or BASE_TOKEN
    if (!Array.isArray(businesses)) return;

    // Fetch trades for each valid business
    businesses.forEach((business) => {
      if (!business?.tokenSymbol) return;
      const BASE_TOKEN =
        business?.network === "EGOCHAIN"
          ? "EGAX"
          : business?.network === "BASE"
          ? "DHV"
          : "EGAX";
      const ticker = `${BASE_TOKEN}-${business.tokenSymbol}`;
      dispatch(fetchTradesData(ticker));
    });
  }, [businesses, dispatch]); // Include all dependencies

  // Safe data access helper
  const getTickerStatus = (ticker) => {
    return {
      trades: Array.isArray(tradesData[ticker]) ? tradesData[ticker] : [],
      isLoading: !!loading[ticker],
      hasData: !!tradesData[ticker]?.length,
    };
  };

  // Handle trade click
  const handleTradeClick = useCallback(
    (businessName) => {
      navigate(`/business/trade/${businessName}`);
    },
    [navigate]
  );

  const handleSearch = useCallback(
    debounce((query) => setSearchQuery(query.toLowerCase()), 300),
    []
  );
  // Optimized filtering & sorting
  // Filter & Sort
  const filteredSortedBusinesses = useMemo(() => {
    let result = businesses;

    // Filter by network
    if (businessChain !== "all") {
      result = result.filter((data) => data?.network === businessChain);
    }

    // Search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter(
        (data) =>
          data?.businessName?.toLowerCase().includes(query) ||
          data?.tokenSymbol?.toLowerCase().includes(query)
      );
    }

    // Sorting logic (avoid mutating original array)
    return [...result].sort((a, b) => {
      switch (selectedSort) {
        case "marketCap":
          return (b.marketCap || 0) - (a.marketCap || 0);
        case "price":
          return (b.tokenPrice || 0) - (a.tokenPrice || 0);
        case "verified":
          return a.businessStatus === "VERIFIED" ? -1 : 1;
        case "new":
        default:
          return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });
  }, [businesses, businessChain, searchQuery, selectedSort]); // Memoize on dependencies

  // Get businesses to display (limit by visibleCount)
  const visibleBusinesses = useMemo(
    () => filteredSortedBusinesses.slice(0, visibleCount),
    [filteredSortedBusinesses, visibleCount]
  );

  // Infinite Scroll with Loading
  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      visibleCount < filteredSortedBusinesses.length &&
      !loadingMore // Prevent multiple calls
    ) {
      setLoadingMore(true);
      setTimeout(() => {
        setVisibleCount((prev) => prev + 15);
        setLoadingMore(false);
      }, 2000); // Simulated loading delay
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [visibleCount, filteredSortedBusinesses, loadingMore]);

  useEffect(() => {
    const eventKey = socketEvents.NEW_BUSINESS;

    const handleBusinessEvent = (businessData) => {
      if (!businessData || typeof businessData !== "object") {
        console.error("Invalid Business Data from Socket!");
        return;
      }

      // console.log("Business socket emission successful!!!", businessData);

      // Ensure businesses object has an `id` before proceeding
      if (!businessData.businesses || !businessData.businesses.businessID) {
        console.error("Missing business ID in socket data!");
        return;
      }

      // Append new business to existing businesses list
      dispatch(populateBusinesses([...businesses, businessData.businesses]));

      // Highlight new business
      setNewlyAdded(
        (prev) => new Set([...prev, businessData.businesses.businessID])
      );

      // Remove highlight after 1 second
      setTimeout(() => {
        setNewlyAdded((prev) => {
          const updated = new Set(prev);
          updated.delete(businessData.businesses.businessID);
          return updated;
        });
      }, 1000);
    };

    socket.on(eventKey, handleBusinessEvent);

    return () => {
      socket.off(eventKey, handleBusinessEvent);
    };
  }, [dispatch, businesses]); // Added `businesses` dependency

  useEffect(() => {
    const welcome = localStorage.getItem("welcomeExchange");

    if (welcome == "true") {
      setWelcomeModal(true);
      setTimeout(() => {
        localStorage.setItem("welcomeExchange", "false");
        setWelcomeModal(false);
      }, 2000);
    }
  }, []);

  console.log(businesses, "businesses");

  console.log("====================================");
  console.log(businessChain);
  console.log("====================================");

  return (
    <>
      {welcomeModal ? (
        <div
          className={`WelcomeModalDiv ${
            welcomeModal === false ? "fade-out" : ""
          }`}
        >
          <div className="container">
            <div className="WelcomeModalDiv_area">
              <img
                src="/White Dhive.svg"
                alt=""
                className="WelcomeModalDiv_area_img"
              />
              <div className="WelcomeModalDiv_area_txt">Exchange</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ExchangeDiv">
          <div className="ExchangeDiv_div1">
            <div className="container">
              <div className="ExchangeDiv_div1_area">
                <div className="HomeSection1_area_1">
                  {/* <button onClick={triggerNewBusiness}>trigger business</button> */}
                  <div className="HomeSection1_area_1_title">
                    Tokenize your real-world business effortlessly in seconds.
                  </div>
                  {business_info ? (
                    <button
                      className="HomeSection1_area_1_btn"
                      onClick={() => navigate("/business")}
                    >
                      Manage Business
                    </button>
                  ) : (
                    <button
                      className="HomeSection1_area_1_btn"
                      onClick={() => navigate("/business/create")}
                    >
                      Tokenize Business
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="ExchangeDiv_div2">
            <div className="container">
              <div className="ExchangeDiv_div2_area">
                <div className="ExchangeDiv_div2_header">
                  <div className="ExchangeDiv_div2_header_cont1">
                    <PreferenceHorizontalIcon className="ExchangeDiv_div2_header_cont1_icon" />
                    <Select
                      placeholder="Sort by:"
                      options={options}
                      isSearchable={false}
                      className="TokenCreationDiv_area_body_div2_select2 ExchangeDiv_div2_header_cont1_select"
                      onChange={(option) => setSelectedSort(option.value)}
                    />
                  </div>
                  <div className="ExchangeDiv_div2_header_cont2">
                    <input
                      type="search"
                      placeholder="Search for business..."
                      className="ExchangeDiv_div2_header_cont2_search"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                </div>
                <div className="ExchangeDiv_div2_body">
                  {Array.isArray(visibleBusinesses) &&
                  visibleBusinesses?.length === 0 ? (
                    <div className="ExchangeDiv_div2_body_nodata_div">
                      <Nodata message={"No business Found"} />
                    </div>
                  ) : (
                    <>
                      <div className="business_list_mobile">
                        <div className="business_list_mobile_heading">
                          RWA businesses
                        </div>
                        {Array.isArray(visibleBusinesses) &&
                          visibleBusinesses?.map((data, index) => {
                            if (!data?.tokenSymbol) return null;
                            const marketCapInUSD =
                              parseFloat(data?.marketCap) *
                              (data?.tokenSymbol === "DHV" ? 0.1 : 0.45);
                            const BASE_TOKEN =
                              data?.network === "EGOCHAIN"
                                ? "EGAX"
                                : data?.network === "BASE"
                                ? "DHV"
                                : "EGAX";
                            const ticker = `${BASE_TOKEN}-${data.tokenSymbol}`;
                            const { trades, isLoading, hasData } =
                              getTickerStatus(ticker);
                            return (
                              <div
                                className={`business_list_mobile_cont1 ${
                                  newlyAdded.has(data?.businessID)
                                    ? "highlight"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleTradeClick(data?.businessName)
                                }
                                key={index}
                              >
                                <div className="topGeeBusiness_business_div_cont1">
                                  <span className="businessNameParentDiv_span">
                                    {index + 1}
                                  </span>
                                  <div className="topGeeBusiness_business_div_cont1_img_div">
                                    <img
                                      src={data?.image}
                                      alt=""
                                      className="topGeeBusiness_business_div_cont1_img"
                                    />
                                    <div className="origin_div">
                                      {data.network === "EGOCHAIN" ? (
                                        <img
                                          src="/img/egax_logo.png"
                                          alt=""
                                          className="origin_div_img"
                                        />
                                      ) : data.network === "BASE" ? (
                                        <img
                                          src="/img/baseLogo.png"
                                          alt=""
                                          className="origin_div_img"
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="topGeeBusiness_business_div_cont2">
                                  <div className="topGeeBusiness_business_div_cont2_div1">
                                    <div className="topGeeBusiness_business_div_cont2_div1_name">
                                      {data?.businessName}
                                    </div>
                                    {data?.businessStatus === "UNVERIFIED" ? (
                                      <UnavailableIcon className="tableBody_row_data_stats_div_title_verifyBadge_warn" />
                                    ) : (
                                      <CheckmarkBadge01Icon className="tableBody_row_data_stats_div_title_verifyBadge" />
                                    )}
                                    <span className="tableBody_row_data_stats_div_title_span">
                                      ${data?.tokenSymbol}{" "}
                                      <img
                                        alt={data?.country}
                                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data?.country}.svg`}
                                        className="tableBody_row_data_stats_div_title_span_country"
                                      />
                                    </span>
                                  </div>
                                  <div className="topGeeBusiness_business_div_cont2_div2">
                                    Market cap: $
                                    {FormatNumber(
                                      parseFloat(data?.marketCap) *
                                        (data?.tokenSymbol === "DHV"
                                          ? 0.1
                                          : 0.45)
                                    )}
                                  </div>
                                  <div className="topGeeBusiness_business_div_cont2_div3">
                                    Created by{" "}
                                    <div className="topGeeBusiness_business_div_cont2_div3_addr">
                                      <Blockies
                                        seed={data?.createdBy || "0xXXXX"}
                                        size={4}
                                        scale={4}
                                        className="blockies_icon"
                                      />{" "}
                                      {AddressShortener(data?.createdBy)}
                                    </div>
                                  </div>
                                  <div className="topGeeBusiness_business_div_cont2_div3_time_div">
                                    <ReactTimeAgo
                                      date={data?.createdAt}
                                      locale="en-US"
                                    />
                                  </div>
                                </div>
                                {ticker && (
                                  <MiniChart
                                    ticker={ticker}
                                    baseToken={BASE_TOKEN}
                                  />
                                )}
                                <div className="verified_div">
                                  {marketCapInUSD >= 100000 ? (
                                    <button className="lowRiskBtn">
                                      Low{" "}
                                      <Alert02Icon className="highRiskBtn_icon" />
                                    </button>
                                  ) : marketCapInUSD >= 50000 ? (
                                    <button className="mediumRiskBtn">
                                      Medium{" "}
                                      <Alert02Icon className="highRiskBtn_icon" />
                                    </button>
                                  ) : (
                                    <button className="highRiskBtn">
                                      High{" "}
                                      <Alert02Icon className="highRiskBtn_icon" />
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {/* ==== */}
                      {/* ==== */}
                      {/* ==== */}
                      {/* ==== */}
                      {/* ==== */}
                      {/* ==== */}
                      <table className="TableCompWithDiv_cont_table business_list_desktop">
                        <thead className="TableCompWithDiv_cont_head">
                          <tr className="TableCompWithDiv_cont_head_titles">
                            <th className="TableCompWithDiv_cont_head_titles_div TableCompWithDiv_cont_head_titles_div_first">
                              RW Business
                            </th>
                            <th className="TableCompWithDiv_cont_head_titles_div rm_mobi">
                              Stats
                            </th>
                            <th className="TableCompWithDiv_cont_head_titles_div">
                              <div className="TableCompWithDiv_cont_head_titles_div_txt">
                                Chart
                              </div>
                            </th>
                            <th className="TableCompWithDiv_cont_head_titles_div rm_mobi ">
                              Latest Trades
                            </th>
                            <th className="TableCompWithDiv_cont_head_titles_div  TableCompWithDiv_cont_head_titles_div_last rm_mobi">
                              Volatility
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tableBody">
                          {Array.isArray(visibleBusinesses) &&
                            visibleBusinesses?.map((data, index) => {
                              if (!data?.tokenSymbol) return null;
                              const marketCapInUSD =
                                parseFloat(data?.marketCap) *
                                (data?.tokenSymbol === "DHV" ? 0.1 : 0.45);
                              const BASE_TOKEN =
                                data?.network === "EGOCHAIN"
                                  ? "EGAX"
                                  : data?.network === "BASE"
                                  ? "DHV"
                                  : "EGAX";
                              const ticker = `${BASE_TOKEN}-${data.tokenSymbol}`;
                              const { trades, isLoading, hasData } =
                                getTickerStatus(ticker);

                              return (
                                <tr
                                  className={`tableBody_row ${
                                    newlyAdded.has(data?.businessID)
                                      ? "highlight"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleTradeClick(data?.businessName)
                                  }
                                  key={index}
                                >
                                  <td className="tableBody_row_data tableBody_row_data_first">
                                    <div className="businessNameParentDiv">
                                      <span className="businessNameParentDiv_span">
                                        {index + 1}
                                      </span>
                                      <div className="tableBody_row_data_divname">
                                        <div className="topGeeBusiness_business_div_cont1_img_div">
                                          <img
                                            src={data?.image}
                                            alt=""
                                            className="topGeeBusiness_business_div_cont1_img"
                                          />
                                          <div className="origin_div">
                                            {data.network === "EGOCHAIN" ? (
                                              <img
                                                src="/img/egax_logo.png"
                                                alt=""
                                                className="origin_div_img"
                                              />
                                            ) : data.network === "BASE" ? (
                                              <img
                                                src="/img/baseLogo.png"
                                                alt=""
                                                className="origin_div_img"
                                              />
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="tableBody_row_data_name_Div">
                                          <div className="tableBody_row_data_stats_div_title">
                                            <span className="tableBody_row_data_stats_div_title_span2">
                                              {data?.businessName}
                                              {data?.businessStatus ===
                                              "UNVERIFIED" ? (
                                                <UnavailableIcon className="tableBody_row_data_stats_div_title_verifyBadge_warn" />
                                              ) : (
                                                <CheckmarkBadge01Icon className="tableBody_row_data_stats_div_title_verifyBadge" />
                                              )}
                                            </span>
                                            <span className="tableBody_row_data_stats_div_title_span">
                                              ${data?.tokenSymbol}{" "}
                                              <img
                                                alt={data?.country}
                                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${data?.country}.svg`}
                                                className="tableBody_row_data_stats_div_title_span_country"
                                              />
                                            </span>
                                          </div>
                                          <div className="cashbackDiv">
                                            <div className="cashbackDiv_title">
                                              Product cash back
                                              <InformationCircleIcon className="cashbackDiv_title_icon" />
                                              :
                                            </div>
                                            <div className="cashbackDiv_value">
                                              {data?.burnPercentage}%
                                            </div>
                                          </div>
                                          <div className="tableBody_row_data_first_div_cont1_addr">
                                            <div className="tableBody_row_data_first_div_cont1_addr_time">
                                              <ReactTimeAgo
                                                date={data?.createdAt}
                                                locale="en-US"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="tableBody_row_data rm_mobi">
                                    <div className="tableBody_row_data_stats_div_area">
                                      <div className="tableBody_row_data_stats_div_area_1">
                                        <div className="tableBody_row_data_stats_div_area_1_span1">
                                          Market Cap (USD):
                                        </div>
                                        <div className="tableBody_row_data_stats_div_area_1_span2">
                                          $
                                          {FormatNumber(
                                            parseFloat(data?.marketCap) *
                                              (data?.tokenSymbol === "DHV"
                                                ? 0.1
                                                : 0.45)
                                          )}
                                        </div>
                                      </div>
                                      <div className="tableBody_row_data_stats_div_area_1">
                                        <div className="tableBody_row_data_stats_div_area_1_span1">
                                          Price (USD):
                                        </div>
                                        <div className="tableBody_row_data_stats_div_area_1_span2">
                                          $
                                          {parseFloat(
                                            parseFloat(data?.tokenPrice) *
                                              (data?.tokenSymbol === "DHV"
                                                ? 0.1
                                                : 0.45)
                                          )?.toFixed(4)}
                                        </div>
                                      </div>
                                      <div className="tableBody_row_data_stats_div_area_1">
                                        <div className="tableBody_row_data_stats_div_area_1_span1">
                                          Price (EGAX):
                                        </div>
                                        <div className="tableBody_row_data_stats_div_area_1_span2">
                                          {parseFloat(
                                            data?.tokenPrice
                                          )?.toFixed(4)}{" "}
                                          egax
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="tableBody_row_data">
                                    {ticker && (
                                      <MiniChart
                                        ticker={ticker}
                                        baseToken={BASE_TOKEN}
                                      />
                                    )}
                                  </td>
                                  <td className="tableBody_row_data rm_mobi">
                                    <div className="tableBody_row_data_trades_div">
                                      {isLoading && !hasData ? (
                                        <CustomLoader
                                          borderSize={"2px"}
                                          height={"25px"}
                                          width={"25px"}
                                        />
                                      ) : (
                                        trades
                                          .slice(0, 3)
                                          .map((trade) => (
                                            <TradeRow trade={trade} />
                                          ))
                                      )}
                                    </div>
                                  </td>
                                  <td className="tableBody_row_data tableBody_row_data_last rm_mobi">
                                    <div className="verified_div">
                                      {marketCapInUSD >= 100000 ? (
                                        <button className="lowRiskBtn">
                                          Low{" "}
                                          <Alert02Icon className="highRiskBtn_icon" />
                                        </button>
                                      ) : marketCapInUSD >= 50000 ? (
                                        <button className="mediumRiskBtn">
                                          Medium{" "}
                                          <Alert02Icon className="highRiskBtn_icon" />
                                        </button>
                                      ) : (
                                        <button className="highRiskBtn">
                                          High{" "}
                                          <Alert02Icon className="highRiskBtn_icon" />
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {loadingMore && (
                        <div className="loadMoreBusinessDiv">
                          <CustomLoader
                            borderSize={"3px"}
                            height={"35px"}
                            width={"35px"}
                          />
                          <p className="loadMoreBusinessDiv_txt">
                            Loading more...
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* === */}
      {/* === */}
      {/* === */}
    </>
  );
};

export default Exchange;
