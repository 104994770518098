import React, { useState, useEffect } from "react";
import "./index.css";
import HowItWorks from "../LegalPolicy/HowItWorks";
import { TelegramIcon, NewTwitterIcon } from "hugeicons-react";
import { Link } from "react-router-dom";

const ExchangeFooter = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [howItworks, setHowItworks] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const toggleHowItworks = () => {
    setHowItworks(!howItworks);
  };

  return (
    <div className="ExchangeFooter">
      <div className="container">
        <div className="ExchangeFooter_area">
          <div className="ExchangeFooter_area_1">
            <img
              src="White Dhive.svg"
              alt=""
              className="ExchangeFooter_area_1_img"
            />
            <div className="ExchangeFooter_area_1_links">
              <a
                href="https://t.me/dhivehq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegramIcon className="ExchangeFooter_area_1_links_link1" />
              </a>
              <a
                href="https://x.com/dhivehq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <NewTwitterIcon className="ExchangeFooter_area_1_links_link1" />
              </a>
            </div>
          </div>
          <div className="ExchangeFooter_area_2">
            © {currentYear} Dhive. All rights reserved.
          </div>
          <div className="ExchangeFooter_area_3">
            <Link to="/whitepaper" className="ExchangeFooter_area_3_link1">
              White paper
            </Link>
            <a
              href="https://docs.dhive.org/"
              target="_blank"
              className="ExchangeFooter_area_3_link1"
            >
              Documentation
            </a>
            <Link to="/legal" className="ExchangeFooter_area_3_link1">
              Legal disclaimer
            </Link>
            <div
              onClick={toggleHowItworks}
              style={{ cursor: "pointer" }}
              className="ExchangeFooter_area_3_link1"
            >
              How it works
            </div>
          </div>
        </div>
      </div>
      {howItworks && <HowItWorks close={toggleHowItworks} />}
    </div>
  );
};

export default ExchangeFooter;
